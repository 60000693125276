<template lang="pug">
ChargesRateModal(
  :companyId="CompanySelect.id"
  :chargesRate="chargesRate"
  :dispatchRate="CompanySelect"
  @charges-rate-modal="ChargesRateModal"
)

.home
  .my-3
    h4.mt-3.mb-2 Client Machine Summary {{this.CompanySelect.name}}
    .row(v-if="roles === '0'")
      .col-lg-5
        .form-group
          select.form-control#company(v-model="CompanySelect")
              option(value='0', disabled='') Please Select Company
              option(v-for='item in companylist', v-bind:value='{id: item.Id, name: item.CompanyName}') {{item.CompanyName}}
      .col-lg-12(v-if="this.companySelected") 
        .p-5.my-4.bg-dashbaord.rounded-3
          .row.align-items-center
            //- iframe.giphy-embed(src='https://giphy.com/embed/U7DcmrgSt6hxdwDKcR' width='200' height='200' frameborder='0' allowfullscreen)
            .pl-5.c-primary-dark
              h1.display-5.fw-bold.d-flex.align-items-center.mb-1 
                | Welcome To CMU !
                i.ml-2.cil-smile 
              p Select a company above to have overview of your client company.
    //- If in client view, It will straight display the company overview
    div(v-if="this.globalcompany != null")
      .row
        .col-lg-6
          .dashboard__title
            div
              h5 Production Yield 
              .mb-2
                a(@click="selectYield('month')") Month | 
                a(@click="selectYield('threemonth')") 3 Month | 
                a(@click="selectYield('sixmonth')") 6 Month | 
                a(@click="selectYield('year')") Year | 
              .form-group
                select.form-control(v-model='yearYieldfilter' v-if="this.timedisplay !== 'year'")
                  option(v-for='item in yeardisplay', v-bind:value='item') {{item}}
                select.form-control(v-model='yieldfilter')
                  template(v-if="this.timedisplay === 'month'")
                    option(v-for='item in monthdisplay', v-bind:value='item') {{ item }}
                  template(v-if="this.timedisplay === 'threemonth'")
                    option(v-for='item in threemonthdisplay', v-bind:value='item') {{ item }}
                  template(v-if="this.timedisplay === 'sixmonth'")
                    option(v-for='item in sixmonthdisplay', v-bind:value='item') {{ item }}
                  template(v-if="this.timedisplay === 'year'")
                    option(v-for='item in yeardisplay', v-bind:value='item') {{ item }}
            .btn.btn-append(type='button' v-on:click="ExcelDownloadYield") Download
          .d-flex.my-3.justify-content-center
              .btn.mx-3(@click="showgraph('percentage')" :class="{ 'btn-primary' : PercentageGraph , 'btn-append': NoGloveGraph }") Percentage
              .btn.mx-3(@click="showgraph('noglove')" :class="{ 'btn-primary' : NoGloveGraph , 'btn-append': PercentageGraph }") No Glove
          div(v-if="PercentageGraph")
            div(:class="{ 'graph__isLoading' : graphisLoading }")
              Chart(type="line" :data="productionyield" :options="basicOptions")
          div(v-if="NoGloveGraph")
            div(:class="{ 'graph__isLoading' : graphisLoading }")
              Chart(type="bar" :data="productionyieldNoGlove" :options="basicOptions")
          
        .col-lg-6
          .d-flex.flex-column.justify-content-between.h-100
            .dashboard__title
              div
                h5 Production Volume
                .mb-2
                  a(@click="volumeselect('month')") Month | 
                  a(@click="volumeselect('threemonth')") 3 Month | 
                  a(@click="volumeselect('sixmonth')") 6 Month | 
                  a(@click="volumeselect('year')") Year | 
                .form-group
                  select.form-control(v-model='yearVolumefilter' v-if="this.volumetimedisplay !== 'year'")
                    option(v-for='item in yeardisplay', v-bind:value='item') {{item}}
                  select.form-control(v-model='volumefilter')
                    template(v-if="this.volumetimedisplay === 'month'")
                      option(v-for='item in monthdisplay', v-bind:value='item') {{ item }}
                    template(v-if="this.volumetimedisplay === 'threemonth'")
                      option(v-for='item in threemonthdisplay', v-bind:value='item') {{ item }}
                    template(v-if="this.volumetimedisplay === 'sixmonth'")
                      option(v-for='item in sixmonthdisplay', v-bind:value='item') {{ item }}
                    template(v-if="this.volumetimedisplay === 'year'")
                      option(v-for='item in yeardisplay', v-bind:value='item') {{ item }}
              .btn.btn-append(type='button' v-on:click="ExcelDownloadVolume") Download
            div(:class="{ 'graph__isLoading' : graphisLoading }")
              Chart(type="bar" :data="productionvolume" :options="basicOptions")
      .row.mt-5
        .col-lg-4
          .dashboard__title
            h5 Machine Occupancy
            //- .btn.btn-append(type='button'  v-on:click="ExcelDownloadMO") Download
          Chart(type="doughnut" :data="machineoccupancy" :options="basicOptions")
        .col-lg-4
          .dashboard__title
            h5 Calibration Due Date
          DataTable(:value="callibrations" :paginator="true" :rows="4" responsiveLayout="scroll")
            Column(field="SerialNumber" header="Machine S/N" :sortable="true" filterMatchMode="startsWith")
            Column(field="Calibrationdate" header="Due Date" :sortable="true" dataType="date")
              template(#body="slotProps") {{formatDate(slotProps.data.Calibrationdate)}}
        .col-lg-4
          .dashboard__button.button__wrapper(v-if="roles === '0'")
            .btn.btn-primary(@click="this.chargesRate = true") CHARGES RATE
            router-link(:to="{name: 'Cards', params: {id: this.globalcompany}}").btn.btn-primary RFID CARDS
            router-link(:to="{name: 'Software Management', params: {id: this.globalcompany}}").btn.btn-primary SOFTWARE VERSION
            router-link(:to="{name: 'Machine', params: {id: this.globalcompany}}").btn.btn-primary VIEW MACHINES
            router-link(:to="{name: 'Operator Data', params: {id: this.globalcompany}}").btn.btn-primary OPERATOR DATA
            router-link(:to="{name: 'Product Setting', params: {id: this.globalcompany}}").btn.btn-primary PRODUCT SETTING
          .dashboard__button.button__wrapper(v-if="roles === '2' || roles === '4'")
            router-link(:to="{name: 'Operator Data', params: {id: this.globalcompany}}").btn.btn-primary OPERATOR DATA
            router-link(:to="{name: 'Product Setting', params: {id: this.globalcompany}}").btn.btn-primary PRODUCT SETTING
          

</template>
<style lang="scss">
@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
.graph {
	&__isLoading {
		background: rgba($color: #585656, $alpha: 0.2);
		z-index: 1000;
		position: relative;
		&::after {
			display: block;
			content: " ";
			border: 6px solid #f3f3f3; /* Light grey */
			border-top: 5px solid lightgrey; /* Blue */
			border-radius: 50%;
			width: 25px;
			height: 25px;
			animation: spin 2s linear infinite;
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
	// &__noValue{
	//   background: rgba($color: #000000, $alpha: .3);
	//   z-index: 1000;
	//   position: relative;
	//     &::after{
	//     display: block;
	//     content: "No value Available ";
	//     position: absolute;
	//     top:50%;
	//     left:50%;
	//     transform: translate(-50%,-50%);
	//   }
	// }
}
</style>
<script>
// @ is an alias to /src
import ChargesRateModal from "@/components/ChargesRateModal.vue";
import axios from "axios";
import moment from "moment";
export default {
	name: "Home",
	components: {
		ChargesRateModal,
	},
	created() {
		if (this.$cookies.get("Roles") == 0) this.globalcompany = null;
	},
	data() {
		return {
			graphisLoading: true,
			PercentageGraph: true,
			NoGloveGraph: false,
			chargesRate: false,
			// callibrations: [],
			CompanySelect: 0,
			companyIDSelected: null,
			companySelected: true,
			globalcompany: this.$cookies.get("CompanyID"),
			userid: parseInt(this.$cookies.get("UserID")),
			roles: this.$cookies.get("Roles"),
			month: "month",
			threemonth: "threemonth",
			sixmonth: "sixmonth",
			year: "year",
			machineoccupancy: {
				labels: ["Production", "Standby", "Off"],
				datasets: [
					{
						data: [1, 2, 3],
						backgroundColor: ["#3e8d80", "#FBD470", "#F1435B"],
						hoverBackgroundColor: ["#3e8d80", "#FBD470", "#F1435B"],
					},
				],
			},
			chartLabel: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			],
			monthdisplay: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			yieldfilter: new Date().getFullYear(),
			yearYieldfilter: new Date().getFullYear(),
			volumefilter: new Date().getFullYear(),
			yearVolumefilter: new Date().getFullYear(),
			threemonthdisplay: ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"],
			sixmonthdisplay: ["Jan-Jun", "Jul-Dec"],
			yeardisplay: [new Date().getFullYear()-1, new Date().getFullYear()],
			timedisplay: "year",
			volumetimedisplay: "year",
			basicOptions: {
				plugins: {
					legend: {
						labels: {
							color: "#495057",
						},
					},
				},
			},
			productionvolume: {
				labels: [],
				datasets: [],
			},
			productionyield: {
				labels: [],
				datasets: [],
			},
		};
	},
	computed: {
		companylist() {
			return this.$store.state.companylist;
		},
		callibrations() {
			return this.$store.state.machinelist;
		},
		damachinestatus() {
			return this.$store.state.damachinestatus;
		},
		yield() {
			return this.$store.state.Yield;
		},
	},
	mounted() {
		clearInterval(this.timer);
		this.timer = null;
		this.$store.dispatch("getCompanyInfo");
		if (this.$cookies.get("CompanyID") != null) {
			this.$store.dispatch("getMachineList", this.$cookies.get("CompanyID"));
			this.$store.dispatch(
				"getDAMachineStatus",
				this.$cookies.get("CompanyID")
			);
			this.$store.dispatch("GetYieldCompany", this.$cookies.get("CompanyID"));
		}
		this.CompanySelect = "0";
	},

	watch: {
		callibrations: function (val) {
			return val;
		},
		CompanySelect: function (val) {
			this.productionyield = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "Pass",
						data: [],
						borderColor: "#52B9A8",
						backgroundColor: "rgba(82,185,168,0.2)",
					},
					{
						label: "Retest",
						data: [],
						borderColor: "#f9b115",
						backgroundColor: "rgba(249,177,21,0.2)",
					},
					{
						label: "Fail",
						data: [],
						borderColor: "#F1435B",
						backgroundColor: "rgba(241,67,91,0.2)",
					},
					{
						label: "Reject",
						data: [],
						borderColor: "#8F9BB3",
						backgroundColor: "rgba(143,155,179,0.2)",
					},
				],
			};
			this.productionyieldNoGlove = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "No Glove",
						data: [],
						backgroundColor: "#3e8d80",
					},
				],
			};
			this.productionvolume = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "Production Volume",
						backgroundColor: "#3e8d80",
						data: [],
					},
				],
			};
			if (val.id > 0) {
				this.timedisplay = "year";
				this.volumetimedisplay = "year";
				this.yieldfilter = new Date().getFullYear();
				this.yearYieldfilter = new Date().getFullYear();
				this.volumefilter = new Date().getFullYear();
				this.yearVolumefilter = new Date().getFullYear();
				this.companySelected = false;
				this.$cookies.set("CompanyID", val.id);
				this.$cookies.set("CompanyName", val.name);
				this.globalcompany = val.id;
				this.$store.dispatch("getCompanyInfo");
				this.$store.dispatch("getMachineList", val.id);
				this.$store.dispatch("getDAMachineStatus", val.id);
				this.$store.dispatch("GetYieldCompany", val.id);
				//this.productionupdate();
				this.$store.dispatch("getRateSetting", val.id);
			} else {
				this.companySelected = true;
			}
		},
		yearYieldfilter(val) {
			sync: true;
			this.generateYield(val);
		},
		yieldfilter(val) {
			sync: true;
			let year = this.yearYieldfilter;
			this.generateYield(year, val);
		},
		yearVolumefilter(val) {
			sync: true;
			this.generateVolume(val);
		},
		volumefilter(val) {
			sync: true;
			let year = this.yearVolumefilter;
			this.generateVolume(year, val);
		},
		yield(val) {
			let PushDataVolumn = [];
			let PushDataPass = [];
			let PushDataReject = [];
			let PushDataRetest = [];
			let PushDataFailed = [];
			let PushDataNoGlove = [];
			for (let i = 1; i <= 12; ++i) {
				if (
					val.filter(
						(x) =>
							x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
					).length > 0
				) {
					let Data = val.filter(
						(x) =>
							x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
					);
					var gloveTotal = 0,
						glovePass = 0,
						gloveFailed = 0,
						gloveRetest = 0,
						gloveReject = 0,
						noGloveTotal = 0;
					Data.forEach((item) => {
						gloveTotal += item.Total;
						glovePass += item.Pass;
						gloveFailed += item.Failed;
						gloveRetest += item.Retest;
						gloveReject += item.Reject;
						noGloveTotal += item.NoGlove;
					});
					PushDataVolumn.push(glovePass);
					PushDataPass.push(((glovePass / gloveTotal) * 100).toFixed(2));
					PushDataFailed.push(((gloveFailed / gloveTotal) * 100).toFixed(2));
					PushDataRetest.push(((gloveRetest / gloveTotal) * 100).toFixed(2));
					PushDataReject.push(((gloveReject / gloveTotal) * 100).toFixed(2));

					PushDataNoGlove.push(noGloveTotal);
					this.graphisLoading = false;
				} else {
					PushDataVolumn.push(0);
					PushDataPass.push(0);
					PushDataFailed.push(0);
					PushDataRetest.push(0);
					PushDataReject.push(0);
					PushDataNoGlove.push(0);
				}
			}
			this.productionyield = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "Pass",
						data: PushDataPass,
						borderColor: "#52B9A8",
						backgroundColor: "rgba(82,185,168,0.2)",
					},
					{
						label: "Retest",
						data: PushDataRetest,
						borderColor: "#f9b115",
						backgroundColor: "rgba(249,177,21,0.2)",
					},
					{
						label: "Fail",
						data: PushDataFailed,
						borderColor: "#F1435B",
						backgroundColor: "rgba(241,67,91,0.2)",
					},
					{
						label: "Reject",
						data: PushDataReject,
						borderColor: "#8F9BB3",
						backgroundColor: "rgba(143,155,179,0.2)",
					},
				],
			};
			this.productionyieldNoGlove = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "No Glove",
						data: PushDataNoGlove,
						backgroundColor: "#3e8d80",
					},
				],
			};
			this.productionvolume = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "Production Volume",
						backgroundColor: "#3e8d80",
						data: PushDataVolumn,
					},
				],
			};
		},
		damachinestatus(value) {
			if (value != null) {
				this.machineoccupancy = {
					labels: ["Production", "Standby", "Off"],
					datasets: [
						{
							data: [value.Production, value.Standby, value.Off],
							backgroundColor: ["#3e8d80", "#FBD470", "#F1435B"],
							hoverBackgroundColor: ["#3e8d80", "#FBD470", "#F1435B"],
						},
					],
				};
			} else {
				this.machineoccupancy = {
					labels: ["Production", "Standby", "Off"],
					datasets: [
						{
							data: [0, 0, 0],
							backgroundColor: ["#3e8d80", "#FBD470", "#F1435B"],
							hoverBackgroundColor: ["#3e8d80", "#FBD470", "#F1435B"],
						},
					],
				};
			}
		},
	},
	methods: {
		ChargesRateModal(event) {
			this.chargesRate = event;
		},
		showgraph(graph) {
			if (graph == "percentage") {
				this.PercentageGraph = true;
				this.NoGloveGraph = false;
			} else if (graph == "noglove") {
				this.NoGloveGraph = true;
				this.PercentageGraph = false;
			}
		},
		ExcelDownloadMO: function (event) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/MachineOccupany/" +
						this.globalcompany,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/MachineOccupany/" +
						this.globalcompany,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					// link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		ExcelDownloadYield: function (event) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExceldownloadYield/" +
						this.globalcompany +
						"/" +
						this.timedisplay +
						"/" +
						this.yieldfilter +
						"/" +
						this.yearYieldfilter +
						"?userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExceldownloadYield/" +
						this.globalcompany +
						"/" +
						this.timedisplay +
						"/" +
						this.yieldfilter +
						"/" +
						this.yearYieldfilter +
						"?userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					var now = moment(new Date()).format("YYYYMMDDHHmmss");
					var fileName = "ProductionYield_" + now + ".csv";
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		ExcelDownloadVolume: function (event) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExceldownloadVolume/" +
						this.globalcompany +
						"/" +
						this.volumetimedisplay +
						"/" +
						this.volumefilter +
						"/" +
						this.yearVolumefilter +
						"?userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExceldownloadVolume/" +
						this.globalcompany +
						"/" +
						this.volumetimedisplay +
						"/" +
						this.volumefilter +
						"/" +
						this.yearVolumefilter +
						"?userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: "application/vnd.ms-excel",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					var now = moment(new Date()).format("YYYYMMDDHHmmss");
					var fileName = "ProductionVolume_" + now + ".csv";
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		selectYield: function (event) {
			this.$nextTick(() => {
				if (event == "month") {
					this.timedisplay = "month";
					this.yieldfilter = "Jan";
				} else if (event == "threemonth") {
					this.timedisplay = "threemonth";
					this.yieldfilter = "Jan-Mar";
				} else if (event == "sixmonth") {
					this.timedisplay = "sixmonth";
					this.yieldfilter = "Jan-Jun";
				} else if (event == "year") {
					this.timedisplay = "year";
					this.yieldfilter = this.yearYieldfilter;
				}
			});
		},
		volumeselect: function (event) {
			this.$nextTick(() => {
				if (event == "month") {
					this.volumetimedisplay = "month";
					this.volumefilter = "Jan";
				} else if (event == "threemonth") {
					this.volumetimedisplay = "threemonth";
					this.volumefilter = "Jan-Mar";
				} else if (event == "sixmonth") {
					this.volumetimedisplay = "sixmonth";
					this.volumefilter = "Jan-Jun";
				} else if (event == "year") {
					this.volumetimedisplay = "year";
					this.volumefilter = this.yearVolumefilter;
				}
			});
		},
		generateYield: function () {
			this.graphisLoading = false;
			let PushDataVolumn = [];
			let PushDataPass = [];
			let PushDataReject = [];
			let PushDataRetest = [];
			let PushDataFailed = [];
			let PushDataNoGlove = [];
			let PushLabel = [];
			let yearfilter = this.yearYieldfilter;
			let val = this.yieldfilter;
			switch (this.timedisplay) {
				case "month":
					var index = this.monthdisplay.findIndex((x) => x === val);
					var daysInMonth = new Date(yearfilter, index + 1, 0).getDate();
					for (let i = 1; i <= parseInt(daysInMonth); ++i) {
						PushLabel.push(i);
						if (
							this.yield.filter(
								(x) =>
									x.Day == i &&
									x.Total > 0 &&
									x.Month == index + 1 &&
									x.Years == this.yearYieldfilter
							).length > 0
						) {
							var Data = this.yield.filter(
								(x) =>
									x.Day == i &&
									x.Month == index + 1 &&
									x.Total > 0 &&
									x.Years == this.yearYieldfilter
							);
							let Pass = 0;
							let Failed = 0;
							let Retest = 0;
							let Reject = 0;
							let NoGlove = 0;
							let Total = 0;
							for (var counter = 0; counter < Data.length; ++counter) {
								Pass += parseInt(Data[0].Pass);
								Failed += parseInt(Data[0].Failed);
								Retest += parseInt(Data[0].Retest);
								Reject += parseInt(Data[0].Reject);
								NoGlove += parseInt(Data[0].NoGlove);
								Total += parseInt(Data[0].Total);
							}
							PushDataPass.push(((Pass / Total) * 100).toFixed(2));
							PushDataFailed.push(((Failed / Total) * 100).toFixed(2));
							PushDataRetest.push(((Retest / Total) * 100).toFixed(2));
							PushDataReject.push(((Reject / Total) * 100).toFixed(2));
							PushDataNoGlove.push(NoGlove);
							this.graphisLoading = false;
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
							this.graphisLoading = false;
						}
					}
					this.productionyield = {
						labels: PushLabel,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};

					this.productionyieldNoGlove = {
						labels: PushLabel,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
				case "threemonth":
					let startMonth = 1;
					let endMonth = 0;
					let chartLabelMonth = [];
					switch (val) {
						case "Jan-Mar":
							startMonth = 1;
							endMonth = 3;
							chartLabelMonth = ["January", "February", "March"];
							break;
						case "Apr-Jun":
							startMonth = 4;
							endMonth = 6;
							chartLabelMonth = ["April", "May", "June"];
							break;
						case "Jul-Sep":
							startMonth = 7;
							endMonth = 9;
							chartLabelMonth = ["July", "August", "September"];
							break;
						case "Oct-Dec":
							startMonth = 10;
							endMonth = 12;
							chartLabelMonth = ["October", "November", "December"];
							break;
					}
					for (let i = startMonth; i <= endMonth; ++i) {
						var totalGlove = 0;
						let filteredinMonth = this.yield.filter((x) => x.Month == i);

						if (
							this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							).length > 0
						) {
							let Data = this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							);
							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});
							PushDataPass.push(
								gloveTotal > 0 ? ((glovePass / gloveTotal) * 100).toFixed(2) : 0
							);
							PushDataFailed.push(
								gloveTotal > 0
									? ((gloveFailed / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataRetest.push(
								gloveTotal > 0
									? ((gloveRetest / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataReject.push(
								gloveTotal > 0
									? ((gloveReject / gloveTotal) * 100).toFixed(2)
									: 0
							);

							PushDataNoGlove.push(noGloveTotal);
							this.graphisLoading = false;
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
							this.graphisLoading = false;
						}
					}

					this.productionyield = {
						labels: chartLabelMonth,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};

					this.productionyieldNoGlove = {
						labels: chartLabelMonth,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
				case "sixmonth":
					let start6Month = 1;
					let end6Month = 0;
					let chartLabel6Month = [];
					switch (val) {
						case "Jan-Jun":
							start6Month = 1;
							end6Month = 6;
							chartLabel6Month = [
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
							];
							break;
						case "Jul-Dec":
							start6Month = 7;
							end6Month = 12;
							chartLabel6Month = [
								"July",
								"August",
								"September",
								"October",
								"November",
								"December",
							];
							break;
					}

					for (let i = start6Month; i <= end6Month; ++i) {
						if (
							this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							).length > 0
						) {
							let Data = this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							);
							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});
							PushDataPass.push(
								gloveTotal > 0 ? ((glovePass / gloveTotal) * 100).toFixed(2) : 0
							);
							PushDataFailed.push(
								gloveTotal > 0
									? ((gloveFailed / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataRetest.push(
								gloveTotal > 0
									? ((gloveRetest / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataReject.push(
								gloveTotal > 0
									? ((gloveReject / gloveTotal) * 100).toFixed(2)
									: 0
							);

							PushDataNoGlove.push(noGloveTotal);
							this.graphisLoading = false;
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
							this.graphisLoading = false;
						}
					}

					this.productionyield = {
						labels: chartLabel6Month,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};
					this.productionyieldNoGlove = {
						labels: chartLabel6Month,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
				case "year":
					for (let i = 1; i <= 12; ++i) {
						if (
							this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yieldfilter
							).length > 0
						) {
							let Data = this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yieldfilter
							);
							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});

							PushDataPass.push(
								gloveTotal > 0 ? ((glovePass / gloveTotal) * 100).toFixed(2) : 0
							);
							PushDataFailed.push(
								gloveTotal > 0
									? ((gloveFailed / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataRetest.push(
								gloveTotal > 0
									? ((gloveRetest / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataReject.push(
								gloveTotal > 0
									? ((gloveReject / gloveTotal) * 100).toFixed(2)
									: 0
							);
							PushDataNoGlove.push(noGloveTotal);
							this.graphisLoading = false;
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
							this.graphisLoading = false;
						}
					}

					this.productionyield = {
						labels: this.chartLabel,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};
					this.productionyieldNoGlove = {
						labels: this.chartLabel,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
			}
		},
		generateVolume: function () {
			let PushDataVolumn = [];
			let PushDataPass = [];
			let PushDataReject = [];
			let PushDataRetest = [];
			let PushDataFailed = [];
			let PushDataNoGlove = [];
			let PushLabel = [];
			let yearfilter = this.yearVolumefilter;
			let val = this.volumefilter;
			switch (this.volumetimedisplay) {
				case "month":
					var index = this.monthdisplay.findIndex((x) => x === val);
					var daysInMonth = new Date(yearfilter, index + 1, 0).getDate();
					for (let i = 1; i <= parseInt(daysInMonth); ++i) {
						PushLabel.push(i);
						if (
							this.yield.filter(
								(x) =>
									x.Day == i &&
									x.Pass > 0 &&
									x.Month == index + 1 &&
									x.Years == this.yearVolumefilter
							).length > 0
						) {
							var Data = this.yield.filter(
								(x) =>
									x.Day == i &&
									x.Month == index + 1 &&
									x.Years == this.yearVolumefilter
							);
							PushDataVolumn.push(Data[0].Pass);
							this.graphisLoading = false;
						} else {
							PushDataVolumn.push(0);
							this.graphisLoading = false;
						}
					}

					this.productionvolume = {
						labels: PushLabel,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumn,
							},
						],
					};
					break;
				case "threemonth":
					let startMonth = 1;
					let endMonth = 0;
					let chartLabelMonth = [];
					switch (val) {
						case "Jan-Mar":
							startMonth = 1;
							endMonth = 3;
							chartLabelMonth = ["January", "February", "March"];
							break;
						case "Apr-Jun":
							startMonth = 4;
							endMonth = 6;
							chartLabelMonth = ["April", "May", "June"];
							break;
						case "Jul-Sep":
							startMonth = 7;
							endMonth = 9;
							chartLabelMonth = ["July", "August", "September"];
							break;
						case "Oct-Dec":
							startMonth = 10;
							endMonth = 12;
							chartLabelMonth = ["October", "November", "December"];
							break;
					}

					for (let i = startMonth; i <= endMonth; ++i) {
						if (
							this.yield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							).length > 0
						) {
							let Data = this.yield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							);
							var volumnTotal = 0;
							Data.forEach((item) => {
								volumnTotal += item.Pass;
							});
							PushDataVolumn.push(volumnTotal);
							this.graphisLoading = false;
						} else {
							PushDataVolumn.push(0);
							this.graphisLoading = false;
						}
					}

					this.productionvolume = {
						labels: chartLabelMonth,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumn,
							},
						],
					};
					break;
				case "sixmonth":
					let start6Month = 1;
					let end6Month = 0;
					let chartLabel6Month = [];
					switch (val) {
						case "Jan-Jun":
							start6Month = 1;
							end6Month = 6;
							chartLabel6Month = [
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
							];
							break;
						case "Jul-Dec":
							start6Month = 7;
							end6Month = 12;
							chartLabel6Month = [
								"July",
								"August",
								"September",
								"October",
								"November",
								"December",
							];
							break;
					}

					for (let i = start6Month; i <= end6Month; ++i) {
						if (
							this.yield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							).length > 0
						) {
							let Data = this.yield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							);
							var volumnTotal = 0;
							Data.forEach((item) => {
								volumnTotal += item.Pass;
							});
							PushDataVolumn.push(volumnTotal);
							this.graphisLoading = false;
						} else {
							PushDataVolumn.push(0);
							this.graphisLoading = false;
						}
					}

					this.productionvolume = {
						labels: chartLabel6Month,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumn,
							},
						],
					};
					break;
				case "year":
					for (let i = 1; i <= 12; ++i) {
						if (
							this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.volumefilter
							).length > 0
						) {
							let Data = this.yield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.volumefilter
							);
							var volumnTotal = 0;
							Data.forEach((item) => {
								volumnTotal += item.Pass;
							});
							PushDataVolumn.push(volumnTotal);
							this.graphisLoading = false;
						} else {
							PushDataVolumn.push(0);
							this.graphisLoading = false;
						}
					}
					this.productionvolume = {
						labels: this.chartLabel,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumn,
							},
						],
					};

					break;
			}
		},
		formatDate: function (value) {
			//return moment(String(value)).format("DD/MM/YYYY");
			return moment(String(value),"YYYY/MM/DD").format("DD/MM/YYYY");
		},
	},
};
</script>
