import "primeflex/primeflex.css";
import "core-js/stable";
import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { CoreuiVue } from "@coreui/vue";
import vSelect from "vue-select";
import vuex from "vuex";
import "primevue/resources/primevue.min.css";
import VueCookies from "vue3-cookies";

//Prime Vue
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Chart from "primevue/chart";
import MultiSelect from "primevue/multiselect";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";

// Core UI Bootstrap
require("@coreui/coreui/js/index.esm.js");

const app = createApp(App);
// Defining global variables
app.config.globalProperties.$api_url = "http://203.106.134.136:8866/api";



/* For URL control - 29 Aug 2023 */
// Local server (access from internet)
//app.config.globalProperties.$server_url = "http://202.188.213.195:8866/api/";
//app.config.globalProperties.$web_url = "://202.188.213.195:80";

// Local server (access from local network)
//app.config.globalProperties.$server_url = "http://192.168.1.75:8866/api/";
//app.config.globalProperties.$web_url = "://192.168.1.75:80";

// Clould server
app.config.globalProperties.$server_url = "https://www.qmaxv4.com:8443/api/";
app.config.globalProperties.$web_url = "s://www.qmaxv4.com/";



// Set Filters
app.config.globalProperties.$filters = {
	capitalize(value) {
		if (!value) {
			return "";
		}
		value = value.toString();
		return value.charAt(0).toUpperCase() + value.slice(1);
	},
	percent(dec) {
		if (dec <= 1) {
			return dec * 100 + "%";
		}
		return "Please enter number less than or equal to 1";
	},
	splitTime(value) {
		let str = value;
		const Arr = str.split("T");
		return Arr[1];
	},
};

//For Route Use
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
		(el) => el.parentNode.removeChild(el)
	);

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

app.config.performance = true;
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.use(vuex);
app.use(PrimeVue);
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(VueCookies);

app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("DataTable", DataTable);
app.component("DataView", DataView);
app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("Calendar", Calendar);
app.component("InputText", InputText);
app.component("Dropdown", Dropdown);
app.component("FileUpload", FileUpload);
app.component("v-select", vSelect);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Toast", Toast);
app.component("Chart", Chart);
app.component("multiselect", MultiSelect);
app.component("Dialog", Dialog);
app.component("Checkbox", Checkbox);

app.mount("#app");
export default app;
