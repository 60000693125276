<template lang="pug">
small.text-right
    
    template(v-if="roles === '0' || roles === '2' || roles === '3' || roles === '4'")
      router-link(to='/Home') Home
    template(v-if="roles === '1' ")
      router-link(to='/client-admin-home') Home
    template(v-if="machineDetailsPage")
      |  / 
      a.c-primary-dark(@click="back()") Machine
      //- router-link(:to="{name: 'Machine Dashboard'}") Machine Dashboard
      //- router-link(:to="{name: 'Machine', params: {id: this.globalcompany}}" v-if="globalcompany > 0") Machine
      //- router-link(:to="{name: 'Machine Dashboard', params: {id: this.globalcompany}}" v-if="globalcompany <= 0") Machine
    |  / 
    router-link(to='') {{ this.$route.name }} 
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
export default {
  components: {
    Breadcrumbs,
  },

  data() {
    return {
      globalcompany: this.$cookies.get("CompanyID"),
      roles: this.$cookies.get("Roles"),
    };
  },
  computed: {
    machineDetailsPage() {
      if (this.$route.name == "Machine Details") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods:{
    back(){
      this.$router.go(-1)
    }
  }
};
</script>
