<template lang="pug">
//- Add Cards
Dialog(header="Header" v-model:visible="addCards" @update:visible="updatemodal")
    template(#header)
        h3 Add RFID Card
    .form-group
        label(for="cardid") Card ID
        input.form-control(type="text" placeholder="Card ID" v-model="cardid")
    .form-group
        label(for='operationLevel') Operation Level
        select.form-control#operationLevel(v-model="selectedOL")
            option(value='0', disabled='') Please Select Operation Level
            option(v-for='item in operationLevel', v-bind:value='{id: item.id, name: item.name}' v-if="roles == 0") {{item.name}}
            option(v-for='item in operationLevel.slice(1)', v-bind:value='{id: item.id, name: item.name}' v-if="roles != 0") {{item.name}}
    .form-group
        label(for='employee') Employee Name
        input.form-control(type="text" placeholder="Employee Name" v-model="employeename")
    template(#footer)
        button.btn.btn-append(@click="updatemodal") Discard
        button.btn.btn-primary(type='button' v-on:click='RFIDSave') Add
</template>
<script>
export default {
	emits: ["cards-modal"],
	props: {
		addCards: Boolean,
	},
	data() {
		return {
			userid: parseInt(this.$cookies.get("UserID")),
			globalcompany: this.$cookies.get("CompanyID"),
			selectedOL: 0,
			cardid: "",
			employeename: "",
			roles: this.$cookies.get("Roles"),
		};
	},
	mounted() {
		this.operationLevel;
	},
	computed: {
		operationLevel() {
			return this.$store.state.operationlevel;
		},
	},
	methods: {
		updatemodal() {
			this.$emit("cards-modal", false);
			this.cardid = "";
			this.selectedOL = "0";
			this.employeename = "";
		},
		RFIDSave: function (event) {
			if (!this.cardid || !this.employeename || this.selectedOL.id === "0") {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the card holder details",
					life: 5000,
				});
			} else {
				const data = JSON.stringify({
					cardid: this.cardid,
					companyid: parseInt(this.$cookies.get("CompanyID")),
					oplevel: this.selectedOL.id,
					employeename: this.employeename,
					remarks: "New Created & Assign",
					createuserid: this.userid,
					modifyuserid: this.userid,
				});
				this.$store.commit("Get_Card", data);
				this.$store
					.dispatch("postAPIwithRandom", "newcard")
					.then((response) => {
						if (response == 200) {
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail:
									this.cardid +
									" for " +
									this.employeename +
									" had successfully added",
								life: 5000,
							});
							this.$store.dispatch("getCardList", {
								companyId: this.$cookies.get("CompanyID"),
								UserId: this.userid,
							});
							this.updatemodal();
						} else {
							this.$toast.add({
								severity: "error",
								summary: "Failed",
								detail: "Card " + this.cardid + " not able to register ",
								life: 5000,
							});
						}
					})
					.catch((error) => {
						if (error.response.status == 400) {
							this.$toast.add({
								severity: "error",
								summary: "Failed",
								detail: "Duplicate RFID found for user: " + this.employeename,
								life: 5000,
							});
						} else {
							this.$toast.add({
								severity: "error",
								summary: "Failed",
								detail: "Card " + this.cardid + " not able to register ",
								life: 5000,
							});
						}
					});
			}
		},
	},
};
</script>
