import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const cards = () => import(/* webpackChunkName: "about" */ "../views/Cards.vue");
const Software = () => import("../views/Software.vue");
const Machine = () => import("../views/Machine.vue");
const MachineDetails = () => import("../views/MachineDetails.vue");
const Operator = () => import("../views/Operator.vue");
const ProductSetting = () => import("../views/ProductSetting.vue");
const ClientsDashboard = () => import("../views/ClientsDashboard.vue");
const MachineDashboard = () => import("../views/MachineDashboard.vue");
const Report = () => import("../views/Report.vue");
const ActivityLog = () => import("../views/ActivityLog.vue");
const Profile = () => import("../views/Profile.vue");
const Login = () => import("../views/Login.vue");
const ReportPreview = () => import("../views/ReportPreview.vue");
const ClientAdminHome = () => import("../views/ClientAdminHome.vue");
const ServiceProviderActivity = () => import("../views/ServiceProviderActivityLog.vue");
const updatePassword = () => import("../views/ResetPassword.vue");

const routes = [
  // Login Page
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      title: 'QMAX - Login',
    }
  },
  {
    path: "/UpdatePassword/:email",
    name: "Update Password",
    component: updatePassword,
    props: true,
    meta: {
      title: 'QMAX - Reset Password',
      requiresGuest: true
    }
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: {
      title: 'QMAX - Dashboard',
    }
  },
  {
    path: "/cards/:id",
    name: "Cards",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: cards,
    props: true,
    meta: {
      title: 'QMAX - Cards',
    }
  },
  {
    path: "/software/:id",
    name: "Software Management",
    component: Software,
    props: true,
    meta: {
      title: 'QMAX - Software Management',
    }
  },
  {
    path: "/machine/:id",
    name: "Machine",
    component: Machine,
    props: true,
    meta: {
      title: 'QMAX - Machines',
    }
  },
  {
    path: "/machine-details/:id",
    name: "Machine Details",
    component: MachineDetails,
    props: true,
    meta: {
      title: 'QMAX - Machine Details',
    }
  },
  {
    path: "/operator/:id",
    name: "Operator Data",
    component: Operator,
    props: true,
    meta: {
      title: 'QMAX - Operator',
    }
  },
  {
    path: "/product-setting/:id",
    name: "Product Setting",
    component: ProductSetting,
    props: true,
    meta: {
      title: 'QMAX - Product Setting',
    }
  },
  {
    path: "/clients-dashboard",
    name: "Clients Dashboard",
    component: ClientsDashboard,
    meta: {
      title: 'QMAX - Client List',
    }
  },
  {
    path: "/machine-dashboard",
    name: "Machine Dashboard",
    component: MachineDashboard,
    meta: {
      title: 'QMAX - Machine List',
    }
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: {
      title: 'QMAX - Report',
    }
  },
  {
    path: "/activity/:id",
    name: "Activity Log",
    component: ActivityLog,
    props: true,
    meta: {
      title: 'QMAX - Activity',
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: 'QMAX - Profile',
    }
  },
  {
    path: "/report-preview/:id",
    name: "Report Preview",
    component: ReportPreview,
    meta: {
      title: 'QMAX - Report Preview',
    }
  },
  // Client Admin Home
  {
    path: "/client-admin-home",
    name: "ClientAdminHome",
    component: ClientAdminHome,
    meta: {
      title: 'QMAX - Dashboard',
    }
  },
  {
    path: "/service-provider-activity",
    name: "Service Provider Activity",
    component: ServiceProviderActivity,
    meta: {
      title: 'QMAX - Activity',
    }
  },
  // Client Admin Home


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
