<template lang="pug">
.machine-details
  .machine__wrapper
    .fixed.fixed__machine(:class="machineColor(machinestatus.RecordStatus)")
      small
        .sub-title Machine : {{machineinfo.SerialNumber}} 
        .sub-title Location : {{machineinfo.Location}}
    .row
      .col-lg-4
        h5 Machine Info
        .mt-2
          .form-group 
            label Company
            p {{machineinfo.CompanyName}}
          .form-group 
            label Serial Number
            p {{machineinfo.SerialNumber}}
          .form-group 
            label Location 
            p {{machineinfo.Location}}
          .form-group 
            label Year Of Manufacturing
            p {{machineinfo.YearOfManufac}}
          .form-group 
            label Software Version 
            p {{machineinfo.Version}}
        h5.mt-5 Calibration
        .mt-2
          .form-group 
            label Due Date
            p {{formatCalibrationDate(machineinfo.Calibrationdate)}}
      .col-lg-4
        h5 Production Info
        .mt-2
          .form-group 
            label Product Type 
            p {{production.cProductType}} 
          .form-group 
            label Product Size
            p {{production.cProductSize}}
          .form-group 
            label Batch No            
            p {{production.cBatchNo}}            
          .form-group 
            label Operator 1 
            p {{production.cOperator1}} 
          .form-group 
            label Operator 2
            p {{production.cOperator2}}	
          .form-group 
            label Operator 3
            p {{production.cOperator3}}
          .form-group 
            label Operator 4
            p {{production.cOperator4}}
          .form-group 
            label Inspector 
            p {{production.cInspector}} 
      .col-lg-4
        h5 Machine Status
        .mt-2
          .form-group 
            label 12V Voltmeter 
            p {{machinestatus._12voltmeter}} V
          .form-group 
            label 24V Voltmeter
            p {{machinestatus._24voltmeter}} V
          .form-group 
            label 12V Ammeter            
            p {{machinestatus._12ammeter}} A          
          .form-group 
            label 24V Ammeter 
            p {{machinestatus._24ammeter}} A
          .form-group 
            label Hour Run (HH:MM)
            p {{machinestatus.HourRun}}
          .form-group 
            label Air Pressure
            p {{machinestatus.AirPressure}} psi
          .form-group 
            label Temperature
            p {{machinestatus.Tempreature}} &#8451
          .form-group 
            label Low Pressure 
            .led__container
              .led__green(v-if="machinestatus.LowPressure == 0")
              .led__red(v-if="machinestatus.LowPressure == 1")
          .form-group 
            label E-Stop 
            .led__container
              .led__green(v-if="machinestatus.Estop == 0")
              .led__red(v-if="machinestatus.Estop == 1")
      .col-lg-12
        h5 Test Distribution
        .test-distribution__wrapper
          .passed
            small passed
            h3 {{production.cPassed}}
            .percentage {{production.cPesPassed}} %
          .failed
            small failed
            h3 {{production.cFailed}}
            .percentage {{production.cPesFailed}} %
          .retest
            small retest
            h3 {{production.cRetest}}
            .percentage {{production.cPesRetest}} %
          .reject
            small reject
            h3 {{production.cReject}}
            .percentage {{production.cPesReject}} %
          .total
            small total
            h3 {{production.cTotal}}
        .row.mt-4
          .col-lg-6
            .form-group.intro
              label.intro No Glove 
              p {{production.cNoGlove}}
            .form-group 
              label Interval
              p {{production.cInterval}}s
            .form-group 
              label Rate 
              p {{production.cRate}} /hr
          .col-lg-6
            .form-group 
              label Round Time
              p {{production.cRoundTime}}s         
            .form-group 
              label Run Time (HH:MM:SS)
              p {{production.cRunTime}} 
            .form-group 
              label Module
              p {{production.cModule}}
            .form-group 
              label Efficiency
              p {{production.cEfficiency}} %
  .machine__graph
    .row
      .col-lg-12
        .my-3
        nav
          .nav.nav-tabs#nav-tab(role='tablist')
            a.nav-item.nav-link.active#nav-production-tab(v-if="roles === '4' || roles === '0' || roles === '2'" data-toggle='tab' href='#nav-production' role='tab' aria-controls='nav-production' aria-selected='true') Production
            a.nav-item.nav-link#nav-engineering-tab(v-if="roles === '3' || roles === '0' || roles === '2' " data-toggle='tab' href='#nav-engineering' role='tab' aria-controls='nav-engineering' aria-selected='false' :class="{ active:roles === '3' }") Engineering
            a.nav-item.nav-link#nav-calibration-tab.fixed.fixed__callibration(v-if="roles === '0'" data-toggle='tab' href='#nav-calibration' role='tab' aria-controls='nav-calibration' aria-selected='false') Calibration
            a.nav-item.nav-link#nav-license-tab.fixed.fixed__license(v-if="roles === '0'" data-toggle='tab' href='#nav-license' role='tab' aria-controls='nav-license' aria-selected='false') License
        .tab-content#nav-tabContent
          .tab-pane.fade.show.active#nav-production(role='tabpanel' aria-labelledby='nav-production-tab' v-if="roles === '4' || roles === '0' || roles === '2'")
            .row 
              .col-lg-12
                h5 Production Yield
                .mb-2
                  a(@click="selectYield('month')") Month | 
                  a(@click="selectYield('threemonth')") 3 Month | 
                  a(@click="selectYield('sixmonth')") 6 Month | 
                  a(@click="selectYield('year')") Year |
                .d-flex.justify-content-between.align-items-center
                  .form-group.mb-0
                    select.form-control(v-model='yearYieldfilter' v-if="this.timedisplay !== 'year'")
                      option(v-for='item in yeardisplay', v-bind:value='item') {{item}}
                    select.form-control(v-model='yieldfilter')
                      template(v-if="this.timedisplay === 'month'")
                        option(v-for='item in monthdisplay', v-bind:value='item') {{ item }}
                      template(v-if="this.timedisplay === 'threemonth'")
                        option(v-for='item in threemonthdisplay', v-bind:value='item') {{ item }}
                      template(v-if="this.timedisplay === 'sixmonth'")
                        option(v-for='item in sixmonthdisplay', v-bind:value='item') {{ item }}
                      template(v-if="this.timedisplay === 'year'")
                        option(v-for='item in yeardisplay', v-bind:value='item') {{ item }}
                  .btn.btn-append(type='button' v-on:click="ExcelDownloadYield") Download
                .d-flex.my-3.justify-content-center
                  .btn.mx-3(@click="showgraph('percentage')" :class="{ 'btn-primary' : PercentageGraph , 'btn-append': NoGloveGraph }") Percentage
                  .btn.mx-3(@click="showgraph('noglove')" :class="{ 'btn-primary' : NoGloveGraph , 'btn-append': PercentageGraph }") No Glove
                .col-lg-8.m-auto
                  div(v-if="PercentageGraph")
                    div(:class="{ 'graph__isLoading' : graphisLoading }")
                      //- template(v-if="PercentageGraph")
                      Chart(type="line" :data="productionyield" :options="basicOptions" ref="chart")
                  div(v-if="NoGloveGraph")
                    div(:class="{ 'graph__isLoading' : graphisLoading }")
                      //- template(v-if="NoGloveGraph")
                      Chart(type="bar" :data="productionyieldNoGlove" :options="basicOptions")
              .col-lg-12.mt-5
                h5 Production Volume
                .mb-2
                  a(@click="volumeselect('month')") Month | 
                  a(@click="volumeselect('threemonth')") 3 Month | 
                  a(@click="volumeselect('sixmonth')") 6 Month | 
                  a(@click="volumeselect('year')") Year | 
                .d-flex.justify-content-between.align-items-center
                  .form-group.mb-0
                    select.form-control(v-model='yearVolumefilter' v-if="this.volumetimedisplay !== 'year'")
                      option(v-for='item in yeardisplay', v-bind:value='item') {{item}}
                    select.form-control(v-model='volumefilter')
                      template(v-if="this.volumetimedisplay === 'month'")
                        option(v-for='item in monthdisplay', v-bind:value='item') {{ item }}
                      template(v-if="this.volumetimedisplay === 'threemonth'")
                        option(v-for='item in threemonthdisplay', v-bind:value='item') {{ item }}
                      template(v-if="this.volumetimedisplay === 'sixmonth'")
                        option(v-for='item in sixmonthdisplay', v-bind:value='item') {{ item }}
                      template(v-if="this.volumetimedisplay === 'year'")
                        option(v-for='item in yeardisplay', v-bind:value='item') {{ item }}
                  .btn.btn-append(type='button' v-on:click="ExcelDownloadVolume") Download
                .col-lg-8.m-auto
                  div(:class="{ 'graph__isLoading' : graphisLoading }")
                    Chart(type="bar" :data="productionvolume" :options="basicOptions")
              .col-lg-12.my-5                
                h5 Batch Info 
                vue-blob-json-csv(@success='handleSuccess', @error='handleError', tag-name='div', file-type='json', file-name='sample', title='Download JSON', :data='batcheslist', confirm='Do you want to download it?')
                .row
                  .col-lg-12.mt-2
                    label Batch No:
                      div.d-inline-block.mx-2
                        InputText.form-control.p-inputtext.p-component(v-model="batchSearch_no",placeholder='Search by Batch No')
                    label Start Date:
                      div.d-inline-block.mx-2
                        Calendar(v-model="batchSearch_startDate" dateFormat="dd/mm/yy" :showButtonBar="true" placeholder='Search by Start Date' :maxDate="today")
                    //- InputText.mx-2(v-model="batchSearch_startDate",placeholder='Search by Start Date' type="date")
                    label End Date:
                      div.d-inline-block.mx-2
                        Calendar(v-model="batchSearch_endDate" dateFormat="dd/mm/yy" :showButtonBar="true" placeholder='Search by End Date' :maxDate="today")
                    //- InputText.form-control.mx-2(v-model="batchSearch_endDate",placeholder='Search by End Date' type="date")
                    .btn.btn-append(type='button' v-on:click="searchBatchbyFilter()") Search
                    div.d-inline-block.align-middle.mx-2
                      Circle2(size="20px" stroke="2px" v-if="batchSearch_loading")
                    b-spinner(small label='Small Spinner')
                    .btn.btn-append.float-right(type='button' v-on:click="BatchDownload($event)") Download
                .row
                  .col-lg-7
                    DataTable(:value='batcheslist', sortMode='multiple', :paginator='true', :rows='10', responsiveLayout='scroll', selectionMode='single', @rowSelect='onRowSelect' )
                      
                      Column(field="cBatchNo" header="Batch No" :sortable="true")
                      Column(field="cStarttime" header="Start Time" :sortable="true"  dataType='date')
                        template(#body="slotProps")  {{formatDate(slotProps.data.cStarttime)}}
                        template(#filter='{filterModel}') calendar(v-model='filterModel.value', dateFormat="dd/mm/yy", placeholder="dd/mm/yyyy")
                      Column(field="cEndtime" header="End Time" :sortable="true" datatype='date')
                        template(#body="slotProps")  {{formatDate(slotProps.data.cEndtime)}}
                        template(#filter='{filterModel}') calendar(v-model='filterModel.value', dateformat='dd/mm/yy', placeholder='dd/mm/yyyy')
                      //- Column(field="cInspector" header="Inspector" :sortable="true" hidden=true)
                      //- Column(field="cProductType" header="Product Type" :sortable="true" hidden=true)
                      //- Column(field="cProductSize" header="Product Size" :sortable="true" hidden=true)
                      //- Column(field="cOperator1_Name" header="Operator 1" :sortable="true" hidden=true)
                      //- Column(field="cOperator2_Name" header="Operator 2" :sortable="true" hidden=true)
                      //- Column(field="cGloveType" header="Glove type" :sortable="true" hidden=true)
                      
                  .col-lg-4.pt-3
                    .row
                      .col-lg-6
                        .form-group
                          label Product Type
                          p {{BatchProductType}}
                        .form-group
                          label Batch No
                          p {{BatchLogBatchNo}}
                        .form-group
                          label Product Size
                          p {{BatchProductSize}}
                        .form-group
                          label Operator 1
                          p {{BatchOperator1}}
                        .form-group
                          label Operator 2
                          p {{batchOperator2}}
                        .form-group
                          label Operator 3
                          p {{batchOperator3}}
                        .form-group
                          label Operator 4
                          p {{batchOperator4}}
                        .form-group
                          label Inspector
                          p {{batchInspector}}
                      .col-lg-6
                        .form-group
                          label Passed
                          p {{batchPassed}} ({{batchPassedPer}})

                        .form-group
                          label Retest
                          p {{batchRetest}} ({{batchRetestPer}})
                        .form-group
                          label Failed
                          p {{batchFailed}} ({{batchFailedPer}})
                        .form-group
                          label Reject
                          p {{batchReject}} ({{batchRejectPer}})
                        .form-group
                          label Total
                          p {{batchTotal}}
                        .form-group
                          label No. Glove
                          p {{batchNoGlove}}
                        .form-group
                          label Rate
                          p {{batchRate}}
                        .form-group
                          label Glove Type
                          p {{batchType}}
          .tab-pane.fade#nav-engineering(role='tabpanel' aria-labelledby='nav-engineering-tab' v-if="roles === '3' || roles === '0' || roles === '2'" :class="{ show : roles === '3' , active : roles === '3' }")
            .row
              .col-lg-12
                h5 Sensor Data
                .row
                  .col-lg-6
                    Chart(type="line" :data="twentyfourvolt" :options="basicOptions")
                  .col-lg-6
                    Chart(type="line" :data="twelvevolt" :options="basicOptions")
                  .col-lg-6
                    Chart(type="line" :data="airpressure" :options="basicOptions")
                  .col-lg-6
                    Chart(type="line" :data="temperature" :options="basicOptions")
              .col-lg-12.mt-5
                h5 Event Log
                .row
                  .col-lg-12.mt-2
                    label Start Date:
                      div.d-inline-block.mx-2
                        Calendar(v-model="eventSearch_startDate" dateFormat="dd/mm/yy" :showButtonBar="true" placeholder='Search by Start Date' :maxDate="today")
                    //- InputText.mx-2(v-model="batchSearch_startDate",placeholder='Search by Start Date' type="date")
                    label End Date:
                      div.d-inline-block.mx-2
                        Calendar(v-model="eventSearch_endDate" dateFormat="dd/mm/yy" :showButtonBar="true" placeholder='Search by End Date' :maxDate="today")
                    //- InputText.form-control.mx-2(v-model="batchSearch_endDate",placeholder='Search by End Date' type="date")
                    .btn.btn-append(type='button' v-on:click="searchEventbyFilter()") Search
                    div.d-inline-block.align-middle.mx-2
                      Circle2(size="20px" stroke="2px" v-if="eventSearch_loading")
                    b-spinner(small label='Small Spinner')
                    .btn.btn-append.float-right(type='button' v-on:click="ExcelEventLog") Download
                DataTable(:value="events", sortMode='multiple' :paginator="true" :rows="10" responsiveLayout="scroll" ref="eventdt")
                  Column(field="cDateTime" header="Date Time" :sortable="true" datatype="date")
                    template(#body="slotProps")  {{formatDate(slotProps.data.cDateTime)}}
                    template(#filter='{filterModel}') calendar(v-model='filterModel.value', dateformat='dd/mm/yy', placeholder='dd/mm/yyyy')
                  Column(field="cEvent" header="Event" :sortable="true")
                  template(#empty) No records found.
                  //- .btn.btn-append(type='button' v-on:click="ExcelEventLog") Download
                //- .overthrow
                //-   table.table
                //-     thead
                //-       tr
                //-         th(scope='col') Date Time
                //-         th(scope='col') Event
                //-     tbody
                //-       tr(v-for="event in events")
                //-         td(scope='row') {{ $filters.splitTime(event.CreateDate) }}
                //-         td {{ event.EventLog }}  
              .col-lg-12.my-5
                h5 Fault Report
                .row
                  .col-lg-12.mt-2
                    label Start Date:
                      div.d-inline-block.mx-2
                        Calendar(v-model="faultSearch_startDate" dateFormat="dd/mm/yy" :showButtonBar="true" placeholder='Search by Start Date' :maxDate="today")
                    //- InputText.mx-2(v-model="batchSearch_startDate",placeholder='Search by Start Date' type="date")
                    label End Date:
                      div.d-inline-block.mx-2
                        Calendar(v-model="faultSearch_endDate" dateFormat="dd/mm/yy" :showButtonBar="true" placeholder='Search by End Date' :maxDate="today")
                    //- InputText.form-control.mx-2(v-model="batchSearch_endDate",placeholder='Search by End Date' type="date")
                    .btn.btn-append(type='button' v-on:click="searchFaultbyFilter()") Search
                    div.d-inline-block.align-middle.mx-2
                      Circle2(size="20px" stroke="2px" v-if="faultSearch_loading")
                    b-spinner(small label='Small Spinner')
                    .btn.btn-append.float-right(type='button' v-on:click="ExcelFaultLog") Download
                DataTable(:value="faults" :paginator="true" :rows="10" responsiveLayout="scroll")
                  Column(field="CreateDate" header="Date Time" :sortable="true")
                    template(#body="slotProps")  {{formatDate(slotProps.data.CreateDate)}}
                    template(#filter='{filterModel}') calendar(v-model='filterModel.value', dateformat='dd/mm/yy', placeholder='dd/mm/yyyy') 
                  Column(field="Event" header="Event" :sortable="true" )
                  Column(field="FaultLog" header="Fault Log" :sortable="true")
                  template(#empty) No records found.
          .tab-pane.fade#nav-calibration(role='tabpanel' aria-labelledby='nav-calibration-tab' v-if="roles === '0'")
            .mt-2.mb-3
              h3.sub-title Calibration
              p Browse for the calibration file and click upload to process the calibration file
            .mt-2.mb-5
              .form-group            
                FileUpload(name='demo[]' mode="advanced" :customUpload="true" @uploader="readyupload" :showCancelButton="true" :showUploadButton="false" :multiple="false" :auto="true" cancelLabel="Reset" chooseLabel="Select" v-model="file")
                  template(#empty)
                    p Drag and drop files to here to upload.
              .form-group
                label(for="calibrationNote") Remark
                textarea#calibrationNote.form-control(type='text' placeholder='Note' rows="4" max-row="6" v-model="calibrationNote")
              .d-flex.justify-content-end 
                .btn.btn-primary(v-on:click='CallibrationSave') Upload
            DataTable(:value="CallibrationList" :paginator="true" :rows="10" responsiveLayout="scroll" selectionMode="single" )
              Column(field="CreateDate" header="Date Time" :sortable="true" style="width:50%") 
              Column(field="Note" header="Note" :sortable="true")
          .tab-pane.fade#nav-license(role='tabpanel' aria-labelledby='nav-license-tab' v-if="roles === '0'")
            .mt-2.mb-3
              h3.sub-title License
              p Browse for the license file and click upload to process the license file
            .mt-2.mb-5
              .form-group            
                FileUpload(name='demo[]' mode="advanced" :customUpload="true" @uploader="readyupload" :showCancelButton="true" :showUploadButton="false" :multiple="false" :auto="true" cancelLabel="Reset" chooseLabel="Select" v-model="file")
                  template(#empty)
                    p Drag and drop files to here to upload.
              .form-group
                label(for="licenseNote") Remark
                textarea#licenseNote.form-control(type='text' placeholder='Note' rows="4" max-row="6" v-model="licenseNote")
              .d-flex.justify-content-end 
                .btn.btn-primary(v-on:click='LicenseSave') Upload
            DataTable(:value="LicenseList" :paginator="true" :rows="10" responsiveLayout="scroll" selectionMode="single")
              Column(field="CreateDate" header="Date Time" :sortable="true" style="width:50%") 
              Column(field="Note" header="Note" :sortable="true")
</template>
<style lang="scss">
@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
.graph {
	&__isLoading {
		background: rgba($color: #585656, $alpha: 0.2);
		z-index: 1000;
		position: relative;
		&::after {
			display: block;
			content: " ";
			border: 6px solid #f3f3f3; /* Light grey */
			border-top: 5px solid lightgrey; /* Blue */
			border-radius: 50%;
			width: 25px;
			height: 25px;
			animation: spin 2s linear infinite;
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
	// &__noValue{
	//   background: rgba($color: #000000, $alpha: .3);
	//   z-index: 1000;
	//   position: relative;
	//     &::after{
	//     display: block;
	//     content: "No value Available ";
	//     position: absolute;
	//     top:50%;
	//     left:50%;
	//     transform: translate(-50%,-50%);
	//   }
	// }
}
</style>
<script>
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
// import Calendar from "primevue/calendar";
import Datepicker from "vue3-date-time-picker";
import { Circle2 } from "vue-loading-spinner";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
	name: "machineinfo",
	components: {
		Circle2,
		Datepicker,
		// Calendar,
	},
	data() {
		return {
			count: 0,
			today: new Date(),
			graphisLoading: true,
			filters: {
				date: {
					operator: FilterOperator.AND,
					constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
				},
			},
			filtersBatch: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				cBatchNo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				cStarttime: { value: null, matchMode: FilterMatchMode.DATE_IS },
			},
			filtersFault: {},
			monthdisplay: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			yieldfilter: new Date().getFullYear()-1,
			yearYieldfilter: new Date().getFullYear()-1,
			volumefilter: new Date().getFullYear()-1,
			yearVolumefilter: new Date().getFullYear()-1,
			threemonthdisplay: ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"],
			sixmonthdisplay: ["Jan-Jun", "Jul-Dec"],
			yeardisplay: [new Date().getFullYear()-1, new Date().getFullYear()],
			timedisplay: "year",
			volumetimedisplay: "year",
			PercentageGraph: true,
			NoGloveGraph: false,
			roles: this.$cookies.get("Roles"),
			chartLabel: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			],
			meterLabel: [],
			BatchProductType: "",
			BatchLogBatchNo: "",
			BatchProductSize: "",
			BatchOperator1: "",
			batchOperator2: "",
			batchOperator3: "",
			batchOperator4: "",
			batchInspector: "",
			batchPassed: "",
			batchPassedPer: "",
			batchRetest: "",
			batchRetestPer: "",
			batchReject: "",
			batchRejectPer: "",
			batchFailed: "",
			batchFailedPer: "",
			batchTotal: "",
			batchNoGlove: "",
			batchRate: "",
			batchType: "",
			batchSearch_no: "",
			batchSearch_startDate: null,
			batchSearch_endDate: null,
			batchSearch_loading: false,
			eventSearch_startDate: null,
			eventSearch_endDate: null,
			eventSearch_loading: false,
			faultSearch_startDate: null,
			faultSearch_endDate: null,
			faultSearch_loading: false,
			calibrationNote: "",
			licenseNote: "",
			batches: [],
			filters: {},
			selectedFault: null,
			faults: null,
			timer: "",
			timerChart: "",
			chartupdated: false,
			meterupdated: false,
			userid: parseInt(this.$cookies.get("UserID")),
			globalcompany: this.$cookies.get("CompanyID"),
			currentmachineid: this.id,
			basicOptions: {
				plugins: {
					legend: {
						labels: {
							color: "#495057",
						},
					},
					animation: {
						duration: 0, // general animation time
					},
				},
			},
			twentyfourvolt: {
				labels: ["0000", "0600", "1200", "1800", "2400"],
				datasets: [
					{
						label: "24V",
						data: [0.89, 0.66, 0.66669, 0, 0, 0, 0, 1],
						fill: true,
						tension: 0.4,
						borderColor: "#52B9A8",
						backgroundColor: "rgba(82,185,168,0.2)",
					},
				],
			},
			twelvevolt: {
				labels: ["0000", "0600", "1200", "1800", "2400"],
				datasets: [
					{
						label: "12V",
						data: [0, 0, 0, 0, 0, 0, 0, 90],
						fill: false,
						borderDash: [5, 5],
						tension: 0.4,
						borderColor: "#f9b115",
						backgroundColor: "rgba(249,177,21,0.2)",
					},
				],
			},
			airpressure: {
				labels: ["0000", "0600", "1200", "1800", "2400"],
				datasets: [
					{
						label: "Air Pressure",
						data: [12, 51, 62, 33, 21, 62, 45],
						fill: true,
						borderColor: "#F1435B",
						tension: 0.4,
						backgroundColor: "rgba(241,67,91,0.2)",
					},
				],
			},
			temperature: {
				labels: ["0000", "0600", "1200", "1800", "2400"],
				datasets: [
					{
						label: "Temprature",
						data: [6, 7, 62, 33, 2, 6, 45],
						fill: false,
						borderDash: [5, 5],
						borderColor: "#5B78F1",
						tension: 0.4,
						backgroundColor: "rgba(91,120,241,0.2)",
					},
				],
			},
		};
	},
	created() {
		this.initFiltersFault();
	},
	computed: {
		machineinfo() {
			return this.$store.state.machineinfo;
		},
		machinestatus() {
			return this.$store.state.machinestatus;
		},
		production() {
			return this.$store.state.production;
		},
		batch() {
			return this.$store.state.batch;
		},
		batcheslist() {
			return this.$store.state.batchlist;
		},
		events() {
			return this.$store.state.eventlist;
		},
		faults() {
			return this.$store.state.faultlist;
		},
		batchlog() {
			return this.$store.state.batchlog;
		},
		batchlistwithbatch() {
			return this.$store.state.batchlistwithbatch;
		},
		CallibrationList() {
			return this.$store.state.callibrationlist;
		},
		LicenseList() {
			return this.$store.state.licenselist;
		},
		DAYield() {
			if (this.$store.state.Yield.length == 0) {
				if (this.count < 3) {
					this.$store.dispatch("GetYield", this.id);
				}
				this.count++;
			}

			return this.$store.state.Yield;
		},
		meter() {
			return this.$store.state.Meter;
		},
	},
	props: ["id"],
	mounted() {
		this.$store.dispatch("ResetMachineDetails");
		this.currentmachineid = this.id;
		this.$store.commit("SET_CurrentMachine", this.currentmachineid);
		this.timer = setInterval(this.fetchEventsList, 2000);	//original (3000) - 09082023
		this.timerChart = setInterval("fetchChart", 3600000);
		this.$store.dispatch("getMachineInfo", this.id);
		this.$store.dispatch("getMachineStatus", this.id);
		this.$store.dispatch("getProduction", this.id);
		this.$store.dispatch("getBatchList", this.id);
		this.$store.dispatch("GetYield", this.id);
		this.$store.dispatch("getEventList", this.id);
		this.$store.dispatch("GetMeter", this.id);
		this.$store.dispatch("getLicense", this.id);
		this.$store.dispatch("getCallibration", this.id);
		this.$store.dispatch("getModuleFaultList", this.id);
	},
	destroyed: function () {
		// clearInterval(this.timer);
		// clearInterval(this.timerChart);
		// this.timer = null;
		// this.timerChart = null;
	},
	unmounted() {
		clearInterval(this.timer);
		clearInterval(this.timerChart);
		this.timer = null;
		this.timerChart = null;
		this.$store.dispatch("ResetMachineDetails");
	},
	watch: {
		$route(to, from) {
			clearInterval(this.timer);
			this.timer = null;
			if (to.path == "/machine/null") {
				this.$router.push({ path: "/machine-dashboard" });
			}
		},
		yearYieldfilter(val) {
			sync: true;
			this.graphisLoading = true;
			this.generateYield();
		},
		yieldfilter(val) {
			sync: true;
			this.graphisLoading = true;
			this.generateYield();
		},
		yearVolumefilter(val) {
			sync: true;
			this.generateVolume();
		},
		volumefilter(val) {
			sync: true;
			this.generateVolume();
		},
		events(val) {},
		meter(val) {
			sync: true;
			let PushData24V = [];
			let PushData12V = [];
			let PushDataAirPressure = [];
			let PushDataTemp = [];

			for (var k = 0; k < val.length; ++k) {
				this.meterLabel.push(val[k].Hourly);
				PushData24V.push(val[k]._24volt);
				PushData12V.push(val[k]._12volt);
				PushDataAirPressure.push(val[k].AirPressure);
				PushDataTemp.push(val[k].Temperature);
			}

			this.twentyfourvolt = {
				labels: this.meterLabel,
				datasets: [
					{
						label: "24V",
						data: PushData24V,
						fill: true,
						tension: 0.4,
						borderColor: "#52B9A8",
						backgroundColor: "rgba(82,185,168,0.2)",
					},
				],
			};

			this.twelvevolt = {
				labels: this.meterLabel,
				datasets: [
					{
						label: "12V",
						data: PushData12V,
						tension: 0.4,
						borderColor: "#f9b115",
						backgroundColor: "rgba(249,177,21,0.2)",
					},
				],
			};

			this.airpressure = {
				labels: this.meterLabel,
				datasets: [
					{
						label: "Air Pressure",
						data: PushDataAirPressure,
						fill: true,
						borderColor: "#F1435B",
						tension: 0.4,
						backgroundColor: "rgba(241,67,91,0.2)",
					},
				],
			};

			this.temperature = {
				labels: this.meterLabel,
				datasets: [
					{
						label: "Temperature",
						data: PushDataTemp,
						borderColor: "#5B78F1",
						tension: 0.4,
						backgroundColor: "rgba(91,120,241,0.2)",
					},
				],
			};
		},
		DAYield(val) {
			if (val.length <= 0) {
				this.graphisLoading = true;
				return;
			}
			this.graphisLoading = false;
			let PushDataVolumnPass = [];
			let PushDataPass = [];
			let PushDataReject = [];
			let PushDataRetest = [];
			let PushDataFailed = [];
			let PushDataNoGlove = [];
			for (let i = 1; i <= 12; ++i) {
				if (val.filter((x) => x.Month == i && x.Total > 0).length > 0) {
					let Data = val.filter(
						(x) => x.Month == i && x.Total > 0 && x.Years == this.yieldfilter
					);
					var gloveTotal = 0,
						glovePass = 0,
						gloveFailed = 0,
						gloveRetest = 0,
						gloveReject = 0,
						noGloveTotal = 0;
					Data.forEach((item) => {
						gloveTotal += item.Total;
						glovePass += item.Pass;
						gloveFailed += item.Failed;
						gloveRetest += item.Retest;
						gloveReject += item.Reject;
						noGloveTotal += item.NoGlove;
					});

					PushDataVolumnPass.push(glovePass);
					PushDataNoGlove.push(noGloveTotal);
					if(gloveTotal !=0){
						PushDataPass.push(((glovePass / gloveTotal) * 100).toFixed(2));
						PushDataFailed.push(((gloveFailed / gloveTotal) * 100).toFixed(2));
						PushDataRetest.push(((gloveRetest / gloveTotal) * 100).toFixed(2));
						PushDataReject.push(((gloveReject / gloveTotal) * 100).toFixed(2));
					}else{
						PushDataPass.push(0);
						PushDataFailed.push(0);
						PushDataRetest.push(0);
						PushDataReject.push(0);
					}
					
				} else {
					PushDataVolumnPass.push(0);
					PushDataPass.push(0);
					PushDataFailed.push(0);
					PushDataRetest.push(0);
					PushDataReject.push(0);
					PushDataNoGlove.push(0);
				}
			}
			this.productionyield = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "Pass",
						data: PushDataPass,
						borderColor: "#52B9A8",
						backgroundColor: "rgba(82,185,168,0.2)",
					},
					{
						label: "Retest",
						data: PushDataRetest,
						borderColor: "#f9b115",
						backgroundColor: "rgba(249,177,21,0.2)",
					},
					{
						label: "Fail",
						data: PushDataFailed,
						borderColor: "#F1435B",
						backgroundColor: "rgba(241,67,91,0.2)",
					},
					{
						label: "Reject",
						data: PushDataReject,
						borderColor: "#8F9BB3",
						backgroundColor: "rgba(143,155,179,0.2)",
					},
				],
			};
			this.productionyieldNoGlove = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "No Glove",
						data: PushDataNoGlove,
						backgroundColor: "#3e8d80",
					},
				],
			};
			this.productionvolume = {
				labels: this.chartLabel,
				datasets: [
					{
						label: "Production Volume",
						backgroundColor: "#3e8d80",
						data: PushDataVolumnPass,
					},
				],
			};
		},
	},
	methods: {
		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
		},
		formatCalibrationDate: function (value) {
			return moment(String(value),"YYYY/MM/DD").format("DD/MM/YYYY");
		},
		ExcelEventLog: function (event) {
			var start = "",
				end = "";
			if (this.eventSearch_startDate != null) {
				start = moment(this.eventSearch_startDate).format("yyyy-MM-DD");
			}

			if (this.eventSearch_endDate != null) {
				end = moment(this.eventSearch_endDate).format("yyyy-MM-DD");
			}
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExcelDownloadEvent/" +
						this.id +
						"?startDate=" +
						start +
						"&endDate=" +
						end +
						"&userid=" +
						this.userid,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExcelDownloadEvent/" +
						this.id +
						"?startDate=" +
						start +
						"&endDate=" +
						end +
						"&userid=" +
						this.userid,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					const current = moment(new Date()).format("YYYYMMDDHHmmss");
					var fileName = "Event_" + this.machineinfo.SerialNumber;

					if (
						this.eventSearch_startDate == null &&
						this.eventSearch_endDate == null
					) {
						fileName += "_" + current;
					} else if (this.eventSearch_startDate == null) {
						fileName +=
							"_# to " + moment(this.eventSearch_endDate).format("yyyyMMDD");
					} else if (this.eventSearch_endDate == null) {
						fileName +=
							"_" +
							moment(this.eventSearch_startDate).format("yyyyMMDD") +
							" to " +
							moment(new Date()).format("yyyyMMDD");
					} else {
						fileName +=
							"_" +
							moment(this.eventSearch_startDate).format("yyyyMMDD") +
							" to " +
							moment(this.eventSearch_endDate).format("yyyyMMDD");
					}
					link.href = url;
					link.setAttribute("download", fileName + ".csv");
					document.body.appendChild(link);
					link.click();
				});
		},
		ExcelFaultLog: function (event) {
			var start = "",
				end = "";
			if (this.faultSearch_startDate != null) {
				start = moment(this.faultSearch_startDate).format("yyyy-MM-DD");
			}

			if (this.faultSearch_endDate != null) {
				end = moment(this.faultSearch_endDate).format("yyyy-MM-DD");
			}

			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExcelDownloadFault/" +
						this.id +
						"?startDate=" +
						start +
						"&endDate=" +
						end +
						"&userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExcelDownloadFault/" +
						this.id +
						"?startDate=" +
						start +
						"&endDate=" +
						end +
						"&userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					const current = moment(new Date()).format("YYYYMMDDHHmmss");
					var fileName = "Fault_" + this.machineinfo.SerialNumber;

					if (
						this.faultSearch_startDate == null &&
						this.faultSearch_endDate == null
					) {
						fileName += "_" + current;
					} else if (this.faultSearch_startDate == null) {
						fileName +=
							"_# to " + moment(this.faultSearch_endDate).format("yyyyMMDD");
					} else if (this.faultSearch_endDate == null) {
						fileName +=
							"_" +
							moment(this.faultSearch_startDate).format("yyyyMMDD") +
							" to " +
							moment(new Date()).format("yyyyMMDD");
					} else {
						fileName +=
							"_" +
							moment(this.faultSearch_startDate).format("yyyyMMDD") +
							" to " +
							moment(this.faultSearch_endDate).format("yyyyMMDD");
					}

					link.href = url;
					link.setAttribute("download", fileName + ".csv");
					document.body.appendChild(link);
					link.click();
				});
		},
		ExcelDownloadYield: function (event) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExceldownloadByMacYield/" +
						this.id +
						"/" +
						this.timedisplay +
						"/" +
						this.yieldfilter +
						"/" +
						this.yearYieldfilter +
						"?userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExceldownloadByMacYield/" +
						this.id +
						"/" +
						this.timedisplay +
						"/" +
						this.yieldfilter +
						"/" +
						this.yearYieldfilter +
						"?userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					const current = moment(new Date()).format("YYYYMMDDHHmmss");
					let fileName =
						"ProductionYield_" +
						this.machineinfo.SerialNumber +
						"_" +
						current +
						".csv";
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		BatchDownload: function (event) {
			var start = "",
				end = "";
			if (this.batchSearch_startDate != null) {
				start = moment(this.batchSearch_startDate).format("yyyy-MM-DD");
			}

			if (this.batchSearch_endDate != null) {
				end = moment(this.batchSearch_endDate).format("yyyy-MM-DD");
			}

			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExcelDownloadBatch/" +
						this.id +
						"?key=" +
						this.batchSearch_no +
						"&startDate=" +
						start +
						"&endDate=" +
						end +
						"&userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExcelDownloadBatch/" +
						this.id +
						"?key=" +
						this.batchSearch_no +
						"&startDate=" +
						start +
						"&endDate=" +
						end +
						"&userId=" +
						this.userid,
					{
						responseType: "blob",
					}
				) */
				
				
				
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					const current = moment(new Date()).format("YYYYMMDDHHmmss");
					let fileName =
						"BatchInfo_" +
						this.machineinfo.SerialNumber +
						"_" +
						current +
						".zip";
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		ExcelDownloadVolume: function (event) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.$server_url + "DA/ExceldownloadByMacVolume/" +
						this.id +
						"/" +
						this.volumetimedisplay +
						"/" +
						this.volumefilter +
						"/" +
						this.yearVolumefilter +
						"?userId=" +
						this.userid,
					{
						responseType: "arraybuffer",
					}
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/DA/ExceldownloadByMacVolume/" +
						this.id +
						"/" +
						this.volumetimedisplay +
						"/" +
						this.volumefilter +
						"/" +
						this.yearVolumefilter +
						"?userId=" +
						this.userid,
					{
						responseType: "arraybuffer",
					}
				) */
				
				
				
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					const current = moment(new Date()).format("YYYYMMDDHHmmss");
					let fileName =
						"ProductionVolume_" +
						this.machineinfo.SerialNumber +
						"_" +
						current +
						".csv";
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		selectYield: function (event) {
			this.$nextTick(() => {
				if (event == "month") {
					this.timedisplay = "month";
					this.yieldfilter = "Jan";
				} else if (event == "threemonth") {
					this.timedisplay = "threemonth";
					this.yieldfilter = "Jan-Mar";
				} else if (event == "sixmonth") {
					this.timedisplay = "sixmonth";
					this.yieldfilter = "Jan-Jun";
				} else if (event == "year") {
					this.timedisplay = "year";
					this.yieldfilter = this.yearYieldfilter;
				}
			});
		},
		volumeselect: function (event) {
			this.$nextTick(() => {
				if (event == "month") {
					this.volumetimedisplay = "month";
					this.volumefilter = "Jan";
				} else if (event == "threemonth") {
					this.volumetimedisplay = "threemonth";
					this.volumefilter = "Jan-Mar";
				} else if (event == "sixmonth") {
					this.volumetimedisplay = "sixmonth";
					this.volumefilter = "Jan-Jun";
				} else if (event == "year") {
					this.volumetimedisplay = "year";
					this.volumefilter = this.yearVolumefilter;
				}
			});
		},
		showgraph(graph) {
			if (graph == "percentage") {
				this.PercentageGraph = true;
				this.NoGloveGraph = false;
			} else if (graph == "noglove") {
				this.NoGloveGraph = true;
				this.PercentageGraph = false;
			}
		},
		machineColor: function (status) {
			var result = [];
			//- change after status rename
			if (status === "PRODUCTION") {
				result.push("operating");
			} else {
				result.push("standby");
			}
			return result;
		},
		fetchEventsList: function () {
			this.$store.dispatch(
				"getMachineStatus",
				this.$store.state.currentmachine
			);
			this.$store.dispatch("getProduction", this.$store.state.currentmachine);
		},
		fetchChart: function () {
			// this.$store.dispatch("getBatchList", this.id);
			this.$store.dispatch("GetYield", this.id);
			this.$store.dispatch("GetMeter", this.id);
		},
		cancelAutoUpdate() {
			clearInterval(this.timer);
		},
		onRowSelect(event) {
			this.BatchProductType = event.data.cProductType;
			this.BatchLogBatchNo = event.data.cBatchNo;
			this.BatchProductSize = event.data.cProductSize;
			this.BatchOperator1 = event.data.cOperator1_Name;
			this.batchOperator2 = event.data.cOperator2_Name;
			this.batchOperator3 = event.data.cOperator3_Name;	//Testing 09062023. Original is ""
			this.batchOperator4 = event.data.cOperator4_Name;	//Testing 09062023. Original is ""
			this.batchInspector = event.data.cInspector_Name;
			this.batchPassed = event.data.cPassed_Op;
			this.batchRetest = event.data.cRetest_Op;
			this.batchReject = event.data.cReject_Op;
			this.batchFailed = event.data.cFailed_Op;
			this.batchNoGlove = event.data.cNoGlove_Op;
			this.batchRate = event.data.cRate;
			this.batchTotal =
				this.batchPassed +
				this.batchRetest +
				this.batchReject +
				this.batchFailed;
			this.batchPassedPer =
				this.batchTotal > 0
					? ((this.batchPassed / this.batchTotal) * 100).toFixed(2) + "%"
					: "0%";
			this.batchRetestPer =
				this.batchTotal > 0
					? ((this.batchRetest / this.batchTotal) * 100).toFixed(2) + "%"
					: "0%";
			this.batchRejectPer =
				this.batchTotal > 0
					? ((this.batchReject / this.batchTotal) * 100).toFixed(2) + "%"
					: "0%";
			this.batchFailedPer =
				this.batchTotal > 0
					? ((this.batchFailed / this.batchTotal) * 100).toFixed(2) + "%"
					: "0%";
			this.batchType = event.data.cGloveType;
			// this.$store.dispatch("getBatchLog", event.data.BatchNo);
		},
		readyupload: function (e) {
			let message = "";
			this.uploaded = e.files;
			e.files.forEach((i, index) => {
				const image = i;
				message += i.name + ",";
				let filenamelist = i.name.split(".");
			});
			this.$toast.add({
				severity: "success",
				summary: "Success Message",
				detail: message + " is ready for upload",
				life: 3000,
			});
		},
		CallibrationSave: function () {
			if (this.uploaded == null) {
				this.$toast.add({
					severity: "error",
					summary: "Error Message",
					detail: "Please select file!",
					life: 3000,
				});
				return;
			}
			if (this.calibrationNote == "") {
				this.$toast.add({
					severity: "error",
					summary: "Error Message",
					detail: "Please enter note!",
					life: 3000,
				});
				return;
			}

			const data = JSON.stringify({
				machineid: this.id,
				note: this.calibrationNote,
				createuserid: this.userid,
				modifyuserid: this.userid,
			});

			let formData = new FormData();
			this.uploaded.forEach((i, index) => {
				formData.append("file", i);
			});
			formData.append("CalibrationInfo", data);
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			axios



				/* For URL control - 29 Aug 2023 */
				.post(
					this.$server_url + "MachineInfo/SaveCallibration",
					formData,
					config
				)

				// Original
				/* .post(
					"https://www.qmaxv4.com:8443/api/MachineInfo/SaveCallibration",
					formData,
					config
				) */
				
				
				
				.then((response) => {
					this.$store.dispatch("getCallibration", this.id);
					this.file = "";
					this.uploaded = null;
					this.$toast.add({
						severity: "success",
						summary: "Success Message",
						detail: "Files was saved into server!",
						life: 3000,
					});
				})
				.catch((error) => {
					this.$toast.add({
						severity: "error",
						summary: "Error Message",
						detail: "Unexpected error occur when sending file!",
						life: 3000,
					});
				});
		},
		LicenseSave: function () {
			if (this.uploaded == null) {
				this.$toast.add({
					severity: "error",
					summary: "Error Message",
					detail: "Please select file!",
					life: 3000,
				});
				return;
			}
			if (this.licenseNote == "") {
				this.$toast.add({
					severity: "error",
					summary: "Error Message",
					detail: "Please enter note!",
					life: 3000,
				});
				return;
			}
			const data = JSON.stringify({
				machineid: this.id,
				note: this.licenseNote,
				createuserid: this.userid,
				modifyuserid: this.userid,
			});

			let formData = new FormData();
			this.uploaded.forEach((i, index) => {
				formData.append("file", i);
			});
			formData.append("VersionInfo", data);
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			axios



				/* For URL control - 29 Aug 2023 */
				.post(
					this.$server_url + "MachineInfo/SaveLicense",
					formData,
					config
				)

				// Original
				/* .post(
					"https://www.qmaxv4.com:8443/api/MachineInfo/SaveLicense",
					formData,
					config
				) */
								
				
				
				.then((response) => {
					this.$store.dispatch("getLicense", this.id);
					this.softwarename = "";
					this.licenseNote = "";
					this.uploaded = null;
					this.$toast.add({
						severity: "success",
						summary: "Success Message",
						detail: "Files was saved into server!",
						life: 3000,
					});
				})
				.catch((error) => {
					this.$toast.add({
						severity: "error",
						summary: "Error Message",
						detail: "Unexpected error occur when sending file!",
						life: 3000,
					});
				});
		},
		generateYield: function () {
			let PushDataVolumnPass = [];
			let PushDataPass = [];
			let PushDataReject = [];
			let PushDataRetest = [];
			let PushDataFailed = [];
			let PushDataNoGlove = [];
			let PushLabel = [];
			let yearfilter = this.yearYieldfilter;
			let val = this.yieldfilter;
			switch (this.timedisplay) {
				case "month":
					var index = this.monthdisplay.findIndex((x) => x === val);
					var daysInMonth = new Date(yearfilter, index + 1, 0).getDate();
					for (let i = 1; i <= parseInt(daysInMonth); ++i) {
						PushLabel.push(i);
						if (
							this.DAYield.filter(
								(x) =>
									x.Day == i &&
									x.Total > 0 &&
									x.Month == index + 1 &&
									x.Years == this.yearYieldfilter
							).length > 0
						) {
							var Data = this.DAYield.filter(
								(x) =>
									x.Day == i &&
									x.Month == index + 1 &&
									x.Years == this.yearYieldfilter
							);
							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});

							if(gloveTotal!=0){
								PushDataPass.push(((glovePass / gloveTotal) * 100).toFixed(2));
								PushDataFailed.push(
									((gloveFailed / gloveTotal) * 100).toFixed(2)
								);
								PushDataRetest.push(
									((gloveRetest / gloveTotal) * 100).toFixed(2)
								);
								PushDataReject.push(
									((gloveReject / gloveTotal) * 100).toFixed(2)
								);
							}else{
								PushDataPass.push(0);
								PushDataFailed.push(0);
								PushDataRetest.push(0);
								PushDataReject.push(0);
							}
							

							PushDataNoGlove.push(noGloveTotal);
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
						}
					}
					this.productionyield = {
						labels: PushLabel,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};

					this.productionyieldNoGlove = {
						labels: PushLabel,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
				case "threemonth":
					let startMonth = 1;
					let endMonth = 0;
					let chartLabelMonth = [];
					switch (val) {
						case "Jan-Mar":
							startMonth = 1;
							endMonth = 3;
							chartLabelMonth = ["January", "February", "March"];
							break;
						case "Apr-Jun":
							startMonth = 4;
							endMonth = 6;
							chartLabelMonth = ["April", "May", "June"];
							break;
						case "Jul-Sep":
							startMonth = 7;
							endMonth = 9;
							chartLabelMonth = ["July", "August", "September"];
							break;
						case "Oct-Dec":
							startMonth = 10;
							endMonth = 12;
							chartLabelMonth = ["October", "November", "December"];
							break;
					}

					for (let i = startMonth; i <= endMonth; ++i) {
						if (
							this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							).length > 0
						) {
							let Data = this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							);
							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});

							if(gloveTotal!=0){
								PushDataPass.push(((glovePass / gloveTotal) * 100).toFixed(2));
								PushDataFailed.push(
									((gloveFailed / gloveTotal) * 100).toFixed(2)
								);
								PushDataRetest.push(
									((gloveRetest / gloveTotal) * 100).toFixed(2)
								);
								PushDataReject.push(
									((gloveReject / gloveTotal) * 100).toFixed(2)
								);
							}
							else{
								PushDataPass.push(0);
								PushDataFailed.push(0);
								PushDataRetest.push(0);
								PushDataReject.push(0);
							}
							PushDataNoGlove.push(noGloveTotal);
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
						}
					}

					this.productionyield = {
						labels: chartLabelMonth,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};

					this.productionyieldNoGlove = {
						labels: chartLabelMonth,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
				case "sixmonth":
					let start6Month = 1;
					let end6Month = 0;
					let chartLabel6Month = [];
					switch (val) {
						case "Jan-Jun":
							start6Month = 1;
							end6Month = 6;
							chartLabel6Month = [
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
							];
							break;
						case "Jul-Dec":
							start6Month = 7;
							end6Month = 12;
							chartLabel6Month = [
								"July",
								"August",
								"September",
								"October",
								"November",
								"December",
							];
							break;
					}

					for (let i = start6Month; i <= end6Month; ++i) {
						if (
							this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							).length > 0
						) {
							let Data = this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yearYieldfilter
							);

							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});

							if(gloveTotal!=0){
								PushDataPass.push(((glovePass / gloveTotal) * 100).toFixed(2));
								PushDataFailed.push(
									((gloveFailed / gloveTotal) * 100).toFixed(2)
								);
								PushDataRetest.push(
									((gloveRetest / gloveTotal) * 100).toFixed(2)
								);
								PushDataReject.push(
									((gloveReject / gloveTotal) * 100).toFixed(2)
								);
							}else{
								PushDataPass.push(0);
								PushDataFailed.push(0);
								PushDataRetest.push(0);
								PushDataReject.push(0);
							}
							
							PushDataNoGlove.push(noGloveTotal);
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
						}
					}

					this.productionyield = {
						labels: chartLabel6Month,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};
					this.productionyieldNoGlove = {
						labels: chartLabel6Month,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
				case "year":
					for (let i = 1; i <= 12; ++i) {
						if (
							this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yieldfilter
							).length > 0
						) {
							let Data = this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.yieldfilter
							);
							var gloveTotal = 0,
								glovePass = 0,
								gloveFailed = 0,
								gloveRetest = 0,
								gloveReject = 0,
								noGloveTotal = 0;
							Data.forEach((item) => {
								gloveTotal += item.Total;
								glovePass += item.Pass;
								gloveFailed += item.Failed;
								gloveRetest += item.Retest;
								gloveReject += item.Reject;
								noGloveTotal += item.NoGlove;
							});

						if(gloveTotal != 0){
							PushDataPass.push(((glovePass / gloveTotal) * 100).toFixed(2));
							PushDataFailed.push(
								((gloveFailed / gloveTotal) * 100).toFixed(2)
							);
							PushDataRetest.push(
								((gloveRetest / gloveTotal) * 100).toFixed(2)
							);
							PushDataReject.push(
								((gloveReject / gloveTotal) * 100).toFixed(2)
							);
						}
						else{
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
						}
							
							PushDataNoGlove.push(noGloveTotal);
						} else {
							PushDataPass.push(0);
							PushDataFailed.push(0);
							PushDataRetest.push(0);
							PushDataReject.push(0);
							PushDataNoGlove.push(0);
						}
					}
					this.productionyield = {
						labels: this.chartLabel,
						datasets: [
							{
								label: "Pass",
								data: PushDataPass,
								borderColor: "#52B9A8",
								backgroundColor: "rgba(82,185,168,0.2)",
							},
							{
								label: "Retest",
								data: PushDataRetest,
								borderColor: "#f9b115",
								backgroundColor: "rgba(249,177,21,0.2)",
							},
							{
								label: "Fail",
								data: PushDataFailed,
								borderColor: "#F1435B",
								backgroundColor: "rgba(241,67,91,0.2)",
							},
							{
								label: "Reject",
								data: PushDataReject,
								borderColor: "#8F9BB3",
								backgroundColor: "rgba(143,155,179,0.2)",
							},
						],
					};
					this.productionyieldNoGlove = {
						labels: this.chartLabel,
						datasets: [
							{
								label: "No Glove",
								data: PushDataNoGlove,
								backgroundColor: "#3e8d80",
							},
						],
					};
					break;
			}
			this.graphisLoading = false;
		},
		generateVolume: function () {
			let PushDataVolumnPass = [];
			let PushLabel = [];
			let yearfilter = this.yearVolumefilter;
			let val = this.volumefilter;
			switch (this.volumetimedisplay) {
				case "month":
					var index = this.monthdisplay.findIndex((x) => x === val);
					var daysInMonth = new Date(yearfilter, index + 1, 0).getDate();
					for (let i = 1; i <= parseInt(daysInMonth); ++i) {
						PushLabel.push(i);
						if (
							this.DAYield.filter(
								(x) =>
									x.Day == i &&
									x.Total > 0 &&
									x.Month == index + 1 &&
									x.Years == this.yearVolumefilter
							).length > 0
						) {
							var Data = this.DAYield.filter(
								(x) =>
									x.Day == i &&
									x.Month == index + 1 &&
									x.Years == this.yearVolumefilter
							);

							var glovePass = 0;
							Data.forEach((item) => {
								glovePass += item.Pass;
							});

							PushDataVolumnPass.push(glovePass);
						} else {
							PushDataVolumnPass.push(0);
						}
					}

					this.productionvolume = {
						labels: PushLabel,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumnPass,
							},
						],
					};
					break;
				case "threemonth":
					let startMonth = 1;
					let endMonth = 0;
					let chartLabelMonth = [];
					switch (val) {
						case "Jan-Mar":
							startMonth = 1;
							endMonth = 3;
							chartLabelMonth = ["January", "February", "March"];
							break;
						case "Apr-Jun":
							startMonth = 4;
							endMonth = 6;
							chartLabelMonth = ["April", "May", "June"];
							break;
						case "Jul-Sep":
							startMonth = 7;
							endMonth = 9;
							chartLabelMonth = ["July", "August", "September"];
							break;
						case "Oct-Dec":
							startMonth = 10;
							endMonth = 12;
							chartLabelMonth = ["October", "November", "December"];
							break;
					}

					for (let i = startMonth; i <= endMonth; ++i) {
						if (
							this.DAYield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							).length > 0
						) {
							let Data = this.DAYield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							);

							var glovePass = 0;
							Data.forEach((item) => {
								glovePass += item.Pass;
							});
							PushDataVolumnPass.push(glovePass);
						} else {
							PushDataVolumnPass.push(0);
						}
					}

					this.productionvolume = {
						labels: chartLabelMonth,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumnPass,
							},
						],
					};
					break;
				case "sixmonth":
					let start6Month = 1;
					let end6Month = 0;
					let chartLabel6Month = [];
					switch (val) {
						case "Jan-Jun":
							start6Month = 1;
							end6Month = 6;
							chartLabel6Month = [
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
							];
							break;
						case "Jul-Dec":
							start6Month = 7;
							end6Month = 12;
							chartLabel6Month = [
								"July",
								"August",
								"September",
								"October",
								"November",
								"December",
							];
							break;
					}

					for (let i = start6Month; i <= end6Month; ++i) {
						if (
							this.DAYield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							).length > 0
						) {
							let Data = this.DAYield.filter(
								(x) =>
									x.Month == i &&
									x.Total > 0 &&
									x.Years == this.yearVolumefilter
							);

							var glovePass = 0;
							Data.forEach((item) => {
								glovePass += item.Pass;
							});
							PushDataVolumnPass.push(glovePass);
						} else {
							PushDataVolumnPass.push(0);
						}
					}

					this.productionvolume = {
						labels: chartLabel6Month,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumnPass,
							},
						],
					};
					break;
				case "year":
					for (let i = 1; i <= 12; ++i) {
						if (
							this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.volumefilter
							).length > 0
						) {
							let Data = this.DAYield.filter(
								(x) =>
									x.Month == i && x.Total > 0 && x.Years == this.volumefilter
							);
							var glovePass = 0;
							Data.forEach((item) => {
								glovePass += item.Pass;
							});
							PushDataVolumnPass.push(glovePass);
						} else {
							PushDataVolumnPass.push(0);
						}
					}
					this.productionvolume = {
						labels: this.chartLabel,
						datasets: [
							{
								label: "Production Volume",
								backgroundColor: "#3e8d80",
								data: PushDataVolumnPass,
							},
						],
					};
					break;
			}
			this.graphisLoading = false;
		},
		initFiltersBatch() {
			this.filtersBatch = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				// cStarttime: {
				//   operator: FilterOperator.AND,
				//   constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
				// },
			};
		},
		initFiltersFault() {
			this.filtersFault = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
		searchBatchbyFilter() {
			this.batchSearch_loading = true;
			var start = "",
				end = "";
			if (this.batchSearch_startDate != null) {
				start = moment(this.batchSearch_startDate).format("yyyy-MM-DD");
			}

			if (this.batchSearch_endDate != null) {
				end = moment(this.batchSearch_endDate).format("yyyy-MM-DD");
			}

			var payload = {};
			payload.id = this.id;
			payload.batchSearch_no = this.batchSearch_no;
			payload.batchSearch_startDate = start;
			payload.batchSearch_endDate = end;

			this.$store
				.dispatch("getBatchListbySearch", payload)
				.then(() => (this.batchSearch_loading = false));
		},
		searchEventbyFilter() {
			this.eventSearch_loading = true;
			var start = "",
				end = "";
			if (this.eventSearch_startDate != null) {
				start = moment(this.eventSearch_startDate).format("yyyy-MM-DD");
			}

			if (this.eventSearch_endDate != null) {
				end = moment(this.eventSearch_endDate).format("yyyy-MM-DD");
			}

			var payload = {};
			payload.id = this.id;
			payload.eventSearch_startDate = start;
			payload.eventSearch_endDate = end;

			this.$store
				.dispatch("getEventLogListbySearch", payload)
				.then(() => (this.eventSearch_loading = false));
		},
		searchFaultbyFilter() {
			this.faultSearch_loading = true;
			var start = "",
				end = "";
			if (this.faultSearch_startDate != null) {
				start = moment(this.faultSearch_startDate).format("yyyy-MM-DD");
			}

			if (this.faultSearch_endDate != null) {
				end = moment(this.faultSearch_endDate).format("yyyy-MM-DD");
			}

			var payload = {};
			payload.id = this.id;
			payload.faultSearch_startDate = start;
			payload.faultSearch_endDate = end;

			this.$store
				.dispatch("getModuleFaultListbySearch", payload)
				.then(() => (this.faultSearch_loading = false));
		},
	},
};
</script>
<style>
.intro {
	color: red;
	font-weight: bold;
}
</style>
