<template lang="pug">
//- Notes
//- If operation level if Level 0 - Unipac , it shouldn't show in Client Admin Account
//- Remark is redundant in this case, no place to key in remark also
.cardlist
  .d-flex.justify-content-between.align-items-center
    h4.mt-3.mb-2 RFID Card List
    div.d-inline-flex
      .btn.btn-info(v-on:click="SyncRFID") Sync RFID
    //- InputText.mb-3.form-control.p-inputtext.p-component(v-model="batchSearch_no",placeholder='Search')
  DataTable(:value="cards" :paginator="true" :rows="10" responsiveLayout="scroll" v-model:filters='filtersRFID' dataKey="id")
    template(#empty) No Card Found.
    template(#loading) Loading Card Data. Please wait.
    template(#header)
      .p-d-flex.p-jc-between
        span.p-input-icon-left
          i.pi.pi-search
          InputText(v-model="filtersRFID['global'].value" placeholder='Search')
    Column(headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible" header="No")
      template(#body="rowIndex")
        | {{ rowIndex.index + 1 }}
    Column(field="Cardid" header="Card ID" :sortable="true" filterMatchMode="startsWith")
    Column(field="Employee" header="Employee Name" :sortable="true" filterMatchMode="startsWith")
    Column(field="Createdate" header="Assigned Date" :sortable="true" filterMatchMode="startsWith")
      template(#body="slotProps") {{formatDate(slotProps.data.Createdate)}}
    Column(field="Oplevel" header="Operation Level" :sortable="true" filterMatchMode="startsWith")
    Column(headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible")
      template(#body="cards,slotProps")
        d.flex
          .btn.btn-small.btn-info.mx-2(v-on:click="EditBox(cards.data)")
            i.cil-pencil
          .btn.btn-small.btn-danger.mx-2(v-if="role=='0'" v-on:click="RemoveRFID(cards.data)") 
            i.cil-trash

Dialog(header="Header" v-model:visible="editRFID" @update:visible="UpdateModal")
    template(#header)
        h3 Edit RFID Card
    .form-group
        label(for="cardid") Card ID
        input.form-control(type="text" placeholder="Card ID" v-model="cardid" :disabled="role!='0'")
    .form-group
        label(for='operationLevel') Operation Level
        select.form-control#operationLevel(v-model="selectedOL")
            option(value='0', disabled='') Please Select Operation Level
            option(v-for='item in operationLevel', v-bind:value='item.id' v-if="roles == 0") {{item.name}}
            option(v-for='item in operationLevel.slice(1)', v-bind:value='item.id' v-if="roles != 0") {{item.name}}
    .form-group
        label(for='employee') Employee Name
        input.form-control(type="text" placeholder="Employee Name" v-model="employeename")
    template(#footer)
        button.btn.btn-append(@click="UpdateModal") Discard
        button.btn.btn-primary(type='button' v-on:click='RFIDUpdate') Save
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";
import axios from "axios";
export default {
	data() {
		return {
			timer: "",
			editRFID: false,
			filtersRFID: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			loadingclients: true,
			roles: this.$cookies.get("Roles"),
			editingCellRows: {},
			editingRows: [],
			cardid: "",
			selectedOL: 0,
			employeename: "",
			selectedId: "",
			role: this.$cookies.get("Roles"),
			companyId: this.$route.params.id,
			userid: parseInt(this.$cookies.get("UserID")),
		};
	},
	originalRows: null,
	props: ["id"],
	created() {
		this.statecurrentcompany;
	},
	computed: {
		cards() {
			return this.$store.state.cards;
		},
		statecurrentcompany: function (event) {
			this.$store.commit("SET_CurrentCompany", this.id);
		},
		operationLevel() {
			return this.$store.state.operationlevel;
		},
	},
	mounted() {
		this.$store.dispatch("getCardList", {
			companyId: this.companyId,
			UserId: this.userid,
		});
		// this.$store.dispatch("getEmployeeList", this.id);
		// this.$store.dispatch("getManagerList", this.id);
		this.loadingclients = false;
		this.operationLevel;
	},
	methods: {
		formatDate: function (val) {
			return moment(val,"YYYY/MM/DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");	// Corrected date sorting - 19122024
		},		
		fetchEventsList: function () {
			this.$store.dispatch("getCardList", this.$store.state.currentcompany);
		},
		cancelAutoUpdate() {
			clearInterval(this.timer);
		},
		RemoveRFID(val) {
			this.$confirm.require({
				message: "Are you sure you want to delete Card ID: " + val.Cardid + "?",
				header: "Confirmation",
				icon: "pi pi-trash",
				accept: () => {
					//callback to execute when user confirms the action
					axios



						/* For URL control - 29 Aug 2023 */
						.post(this.$server_url + "RFID/RemoveCard/" + val.Id)

						// Original
						//.post("https://www.qmaxv4.com:8443/api/RFID/RemoveCard/" + val.Id)
						
						
						
						.then((response) => {
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: "Card Removed successfully.",
								life: 5000,
							});
							this.$store.dispatch("getCardList", {
								companyId: this.$cookies.get("CompanyID"),
								UserId: this.$cookies.get("UserID"),
							});
						});
				},
				reject: () => {
					//callback to execute when user rejects the action
				},
			});
		},
		EditBox(val) {
			this.editRFID = true;
			this.selectedOL = val.OplevelId;
			this.cardid = val.Cardid;
			this.employeename = val.Employee;
			this.selectedId = val.Id;
		},
		RFIDUpdate() {
			const data = JSON.stringify({
				Id: this.selectedId,
				cardid: this.cardid,
				companyid: parseInt(this.$cookies.get("CompanyID")),
				oplevel: this.selectedOL,
				employeename: this.employeename,
				modifyuserid: parseInt(this.$cookies.get("UserID")),
			});

			this.$store.commit("Get_Card", data);

			this.$store
				.dispatch("postAPIwithRandom", "updateRFID")
				.then((response) => {
					if (response == 200) {
						this.$toast.add({
							severity: "success",
							summary: "Success Message",
							detail: this.cardid + "has been updated.",
							life: 5000,
						});
						this.$store.dispatch("getCardList", {
							companyId: this.$cookies.get("CompanyID"),
							UserId: this.$cookies.get("UserID"),
						});
						this.UpdateModal();
					}
				})
				.catch((error) => {
					if (error.response.status == 400) {
						this.$toast.add({
							severity: "error",
							summary: "Failed Message",
							detail: "Duplicated card found for Card: " + this.cardid,
							life: 5000,
						});
					} else {
						this.$toast.add({
							severity: "error",
							summary: "Failed Message",
							detail: this.employeename + " not able to update ",
							life: 5000,
						});
					}
				});
		},
		UpdateModal() {
			this.editRFID = false;
			this.employeename = "";
			this.selectedOL = "";
			this.cardid = "";
			this.Id = "";
		},
		SyncRFID() {
			this.$confirm.require({
				message: "Are you sure you want to sync operators to all machines?",
				header: "Confirmation",
				icon: "pi pi-exclamation-triangle",
				accept: () => {
					//callback to execute when user confirms the action
					axios



						/* For URL control - 29 Aug 2023 */
						.post(
							this.$server_url + "RFID/SyncRFIDById/" +
								this.$cookies.get("CompanyID")
						)

						// Original
						/* .post(
							"https://www.qmaxv4.com:8443/api/RFID/SyncRFIDById/" +
								this.$cookies.get("CompanyID")
						) */
						
						
						
						.then((response) => {
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: "RFIDs Synced",
								life: 3000,
							});
							this.$store.dispatch("getCardList", {
								companyId: this.$cookies.get("CompanyID"),
								UserId: this.$cookies.get("UserID"),
							});
						});
				},
				reject: () => {
					//callback to execute when user rejects the action
				},
			});
		},
	},
};
</script>
