<template lang="pug">
//- Modal Add Machine
Dialog(header="Header" v-model:visible="addMachines"  @update:visible="updatemodal" :style="{ width: '800px' }" :breakpoints="{'960px': '75vw', '640px': '100vw'} ")
    template(#header)
        h3 Create Machine
    .row
        .col-lg-6
            .form-group
                label(for="serial") Serial Number
                input#serial.form-control(type="text" placeholder="Serial Number" v-model="SerialNumber")
            .form-group
                label(for='location') Location
                input#location.form-control(type="text" placeholder="Location" v-model="location")
            .form-group
                label(for='yearsmanufacturing') Years of Manufacturing
                input#yearsmanufacturing.form-control(type="text" placeholder="Years of Manufacturing" v-model="Years")
            //- If add in company machine list page, it will auto input company name
            .form-group(v-if="this.$route.name == 'Machine Dashboard'")
                label(for='company') Company Name
                select.form-control#company(v-model="MachineCompanySelect")
                    option(value='0', disabled='') Please Select Company
                    option(v-for='item in companylist', v-bind:value='{id: item.Id, name: item.CompanyName}') {{item.CompanyName}}
            .form-group
                label(for='software') Software Version
                select.form-control#software(v-model="SoftwareVersionSelect")
                    option(value='0', disabled='') Please Select Software Version
                    option(v-for='item in softwareVersionList', v-bind:value='item') {{item}}
            //- End
        .col-lg-6
            .form-group
                label(for='sshipaddress') SSH IP Address
                input#sshipaddress.form-control(type="text" placeholder="SSH IP Address" v-model="sshipaddress")
            .form-group
                label(for='sshport') SSH Port
                input#sshport.form-control(type="text" placeholder="SSH Port" v-model="sshport")
            .form-group
                label(for='sshusername') SSH User Name
                input#sshusername.form-control(type="text" placeholder="SSH User Name" v-model="sshusername")
            .form-group
                label(for='databaseserver') Database Server
                input#databaseserver.form-control(type="text" placeholder="Database Server" v-model="databaseserver")
            .form-group
                label(for='databasename') Database Name
                input#databasename.form-control(type="text" placeholder="Database Name" v-model="databasename")
            .form-group
                label(for='databaseusername') Database Username
                input#databaseusername.form-control(type="text" placeholder="Database Username" v-model="databaseusername")
            .form-group
                label(for='databasepassword') Database Password
                input#databasepassword.form-control(type="password" placeholder="Database Password" v-model="databasepassword")
            .form-group
                label(for='anydeskID') Anydesk ID 
                input#anydeskID.form-control(type="text" placeholder="Anydesk ID" v-model="anydeskID")
    template(#footer)
        button.btn.btn-append(@click="updatemodal") Discard
        button.btn.btn-primary(type='button' v-on:click='machineSave') Add
</template>
<script>
export default {
	emits: ["machines-modal"],
	props: {
		addMachines: Boolean,
	},
	data() {
		return {
			SerialNumber: "",
			Years: "",
			location: "",
			sshipaddress: "",
			sshport: "",
			sshusername: "",
			databaseserver: "",
			databasename: "",
			databaseusername: "",
			databasepassword: "",
			anydeskID: "",
			userid: parseInt(this.$cookies.get("UserID")),
			MachineCompanySelect: 0,
			SoftwareVersionSelect: 0,
			softwareVersionList: ["V4", "V5"],
		};
	},
	mounted() {
		this.operationLevel;
	},
	computed: {
		companylist() {
			return this.$store.state.companylist;
		},
	},
	methods: {
		updatemodal() {
			this.$emit("machines-modal", false);
			this.SerialNumber = "";
			this.Years = "";
			this.location = "";
			this.sshipaddress = "";
			this.sshport = "";
			this.sshusername = "";
			this.databaseserver = "";
			this.databasename = "";
			this.databaseusername = "";
			this.databasepassword = "";
			this.anydeskID = "";
			this.SoftwareVersionSelect = 0;
		},
		machineSave: function (event) {
			let SelectedMCompanyID = parseInt(this.$cookies.get("CompanyID"));
			if (this.MachineCompanySelect != 0) {
				SelectedMCompanyID = this.MachineCompanySelect.id;
			}
			if (
				!this.SerialNumber ||
				!this.Years ||
				!this.location ||
				this.SoftwareVersionSelect == 0
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the machine details",
					life: 3000,
				});
			} else if (
				!this.sshport ||
				!this.sshipaddress ||
				!this.sshusername ||
				!this.sshusername
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the machine ssh details",
					life: 3000,
				});
			} else if (
				!this.databasename ||
				!this.databaseusername ||
				!this.databasepassword ||
				!this.databaseserver
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the machine database details",
					life: 3000,
				});
			} else {
				const data = JSON.stringify({
					serialnumber: this.SerialNumber,
					yearofmanufac: this.Years,
					location: this.location,
					companyid: SelectedMCompanyID,
					sshipaddress: this.sshipaddress,
					sshport: this.sshport,
					sshusername: this.sshusername,
					databaseserver: this.databaseserver,
					databasename: this.databasename,
					databaseusername: this.databaseusername,
					databasepassword: this.databasepassword,
					createuserid: this.userid,
					modifyuserid: this.userid,
					anydeskid: this.anydeskID,
					targetSoftwareVersion: this.SoftwareVersionSelect,
				});

				this.$store.commit("GET_MachineInfo", data);
				this.$store
					.dispatch("postAPIwithRandom", "newmachine")
					.then((response) => {
						if (response == 200) {
							//Do message pop up here
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: this.SerialNumber + " machine had created",
								life: 3000,
							});
							this.$store.dispatch("getAllMachineList");
							this.updatemodal();
						}
					})
					.catch((error) => {
						this.$toast.add({
							severity: "error",
							summary: "Error Message",
							detail: this.SerialNumber + " is not able to create",
							life: 3000,
						});
					});
			}
		},
	},
};
</script>
