<template lang="pug">
Toast
ConfirmDialog
.logo__cont
  template(v-if="roles === '1'")
    router-link(to='/client-admin-home')
      img(src="@/assets/img/qmax_logo_new.png")
  template(v-else)
    router-link(to='/Home')
      img(src="@/assets/img/qmax_logo_new.png")
  .dropdown
    .profile.dropdown-toggle#dropdownMenuButton(type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false') 
      i.cil-mood-good
    .dropdown-menu(aria-labelledby='dropdownMenuButton')
      router-link(to='/profile')
        span.dropdown-item Profile
      router-link(to='/')
        span.dropdown-item(v-on:click="testing") Logout

#nav
  header(v-if="homePage")
    .header
      template(v-if="roles === '0'")
        router-link(to='/clients-dashboard') Clients <br> Dashboard
        router-link(to='/machine-dashboard') Machine <br> Dashboard
        router-link(to='/report') Report
        router-link(to='/service-provider-activity') Activity <br> Log
      //- Header for Client Admin 
      template(v-else-if="roles === '1'")
        a(@click="this.addEmployee = true") Create <br> Access
        router-link(:to="{name: 'Cards', params: {id: this.globalcompany}}") RFID <br> Cards
        router-link(:to="{name: 'Activity Log', params:{id: this.globalcompany, CompanyName: companyname}}") Activity <br> Log
        //- router-link(to='/service-provider-activity') Activity <br> Log
      //- Header For Client View 
      //- Need to change to the machine list page view
      template(v-else)
        router-link(:to="{name: 'Machine', params: {id: this.globalcompany}}") Machines
    .mr-4.mb-3.d-flex.flex-column
      h3.text-right 
        template(v-if="roles!=='0'") {{this.companyname}}
  header(v-if="listPage")
    .header
      a(@click="this.addCards = true" v-if="this.$route.name == 'Cards' && roles ==='0'") Add<br>Cards
      a(@click="this.addOperator = true" v-if="this.$route.name == 'Operator Data'") Add<br>Operator
      a(@click="this.addProductSetting = true" v-if="this.$route.name == 'Product Setting'") Add<br>Product
      a(@click="this.addClients = true" v-if="this.$route.name == 'Clients Dashboard'") Create<br>Clients
      a(@click="this.addMachines = true" v-if="this.$route.name == 'Machine Dashboard'") Create<br>Machines
      a(@click="this.addReport = true" v-if="this.$route.name == 'Report'") Create<br>Report
    .mr-4.mb-3.d-flex.flex-column
      h3.text-right 
        template(v-if="this.$route.name == 'Cards' || this.$route.name == 'Operator Data' || this.$route.name == 'Product Setting' ")
          | {{ companyname }}
        |  {{ this.$route.name }}
      Breadcrumbs

  header(v-if="softwarePage")
    .header
    .mr-4.mb-3.d-flex.flex-column.mt-4
      h3 
        template(v-if="this.$route.name == 'Software Management' || this.$route.name == 'Activity Log' ") 
            | {{ this.companyname }} 
        |  {{ this.$route.name }}
      Breadcrumbs

  template(v-if="machinePage")
    h3 Machine
      template(v-if="this.$route.name == 'Machine Details'") 
        | | {{machineinfo.SerialNumber}}
    Breadcrumbs

//- Modal Components Start
AddCardsModal(
  :addCards="addCards"
  @cards-modal="CardsModalChange"
)
AddOperatorModal(
  :addOperator="addOperator"
  @operator-modal="OperatorModalChange"
)
AddProductSettingModal(
  :addProductSetting="addProductSetting"
  @product-setting-modal="ProductSettingModalChange"
)
AddClientsModal(
  :addClients="addClients"
  @clients-modal="ClientsModalChange"
)
AddMachinesModal(
  :addMachines="addMachines"
  @machines-modal="MachinesModalChange"
)

AddEmployeeModal(
  :addEmployee="addEmployee"
  @employee-modal="EmployeeModalChange"
)

AddReportModal(
  :addReport="addReport"
  @report-modal="ReportModalChange"
)
//- Modal Components End


</template>
<script>
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import AddClientsModal from "@/components/AddClientsModal.vue";
import AddProductSettingModal from "@/components/AddProductSettingModal.vue";
import AddOperatorModal from "@/components/AddOperatorModal.vue";
import AddEmployeeModal from "@/components/AddEmployeeModal.vue";
import AddCardsModal from "@/components/AddCardsModal.vue";
import AddMachinesModal from "@/components/AddMachinesModal.vue";
import AddReportModal from "@/components/AddReportModal.vue";

export default {
	components: {
		Breadcrumbs,
		AddClientsModal,
		AddProductSettingModal,
		AddOperatorModal,
		AddEmployeeModal,
		AddCardsModal,
		AddMachinesModal,
		AddReportModal,
	},
	data() {
		return {
			addCards: false,
			addOperator: false,
			addProductSetting: false,
			addClients: false,
			addMachines: false,
			addReport: false,
			addEmployee: false,
			CompanySelect: 0,
			maxaccountselect: 0,
			companyIDSelected: null,
			globalcompany: this.$cookies.get("CompanyID"),
			roles: this.$cookies.get("Roles"),
			userid: parseInt(this.$cookies.get("UserID")),
		};
	},
	created: function () {},
	computed: {
		machineinfo() {
			return this.$store.state.machineinfo;
		},
		homePage() {
			if (this.$route.name == "Home" || this.$route.name == "ClientAdminHome") {
				return true;
			} else {
				return false;
			}
		},
		listPage() {
			if (
				this.$route.name == "Cards" ||
				this.$route.name == "Operator Data" ||
				this.$route.name == "Product Setting" ||
				this.$route.name == "Clients Dashboard" ||
				this.$route.name == "Machine Dashboard" ||
				this.$route.name == "Report"
			) {
				return true;
			} else {
				return false;
			}
		},
		softwarePage() {
			if (
				this.$route.name == "Software Management" ||
				this.$route.name == "Activity Log" ||
				this.$route.name == "Profile" ||
				this.$route.name == "Service Provider Activity"
			) {
				return true;
			} else {
				return false;
			}
		},
		machinePage() {
			if (
				this.$route.name == "Machine Details" ||
				this.$route.name == "Machine"
			) {
				return true;
			} else {
				return false;
			}
		},
		operationLevel() {
			return this.$store.state.operationlevel;
		},
		employee() {
			return this.$store.state.employeelist;
		},
		manager() {
			return this.$store.state.managerlist;
		},
		companyname() {
			return this.$route.params.CompanyName == null
				? this.$cookies.get("CompanyName")
				: this.$route.params.CompanyName;
		},
	},
	mounted() {
		this.$store.dispatch("getCompanyInfo");
		this.$store.dispatch("getMachineInfo", this.$store.state.currentcompany);
		this.operationLevel;
	},

	methods: {
		//- For Modal
		ClientsModalChange(event) {
			this.addClients = event;
		},
		ProductSettingModalChange(event) {
			this.addProductSetting = event;
		},
		OperatorModalChange(event) {
			this.addOperator = event;
		},
		EmployeeModalChange(event) {
			this.addEmployee = event;
		},
		CardsModalChange(event) {
			this.addCards = event;
		},
		MachinesModalChange(event) {
			this.addMachines = event;
		},
		ReportModalChange(event) {
			this.addReport = event;
		},
		testing() {
			axios
				


				/* For URL control - 29 Aug 2023 */
				.post(this.$server_url + "User/Logout/" + this.userid)
			
				// Original
				//.post("https://www.qmaxv4.com:8443/api/User/Logout/" + this.userid)



				.then((response) => {
					// this.$toast.add({
					// 	severity: "success",
					// 	summary: "Success Message",
					// 	detail: "Log out successfully",
					// 	life: 5000,
					// });
					// this.$store.dispatch("getProductSetting", this.companyId);
				});
		},
	},
};
</script>
