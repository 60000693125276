<template lang="pug">
//- Modal Operator
Dialog(header="Header" v-model:visible="addOperator"  @update:visible="updatemodal")
	template(#header)
		h3 Add Operator Data
	.form-group
		label(for='employeeid') Operator ID
		input#employeeid.form-control(type="text" placeholder="Operator ID" v-model="employeeid")
	.form-group
		label(for='employeename') Operator Name
		input#employeename.form-control(type="text" placeholder="Operator Name" v-model="employeename")
	
	template(#footer)
		button.btn.btn-append(@click="updatemodal") Discard
		button.btn.btn-primary(type='button' v-on:click='operatorSave') Add
</template>
<script>
export default {
	emits: ["operator-modal"],
	props: {
		addOperator: Boolean,
	},

	data() {
		return {
			userid: parseInt(this.$cookies.get("UserID")),
			globalcompany: this.$cookies.get("CompanyID"),
			companyId: parseInt(this.$cookies.get("CompanyID")),
			employeeid: "",
			employeename: "",
		};
	},
	mounted() {
		this.operationLevel;
	},
	computed: {},
	methods: {
		updatemodal() {
			this.$emit("operator-modal", false);
			this.employeeid = "";
			this.employeename = "";
		},
		operatorSave: function () {
			if (!this.employeeid || !this.employeename) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the operator details",
					life: 5000,
				});
			} else {
				const data = JSON.stringify({
					employeeid: this.employeeid,
					employeename: this.employeename,
					companyid: parseInt(this.$cookies.get("CompanyID")),
					createuserid: this.userid,
					modifyuserid: this.userid,
				});
				this.$store.commit("GET_EmployeeInfo", data);
				this.$store
					.dispatch("postAPIwithRandom", "newoperator")
					.then((response) => {
						if (response == 200) {
							//Do message pop up here
							//Close the modal
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: this.employeename + " had created",
								life: 5000,
							});
							this.$store.dispatch("getEmployeeList", this.companyId);
						}
						this.updatemodal();
					})
					.catch((error) => {
						if (error.response.status == 400) {
							this.$toast.add({
								severity: "error",
								summary: "Failed",
								detail: "Duplicated Id found for " + this.employeename,
								life: 5000,
							});
						} else {
							this.$toast.add({
								severity: "error",
								summary: "Failed",
								detail: this.employeename + " is not able to create",
								life: 5000,
							});
						}
					});
			}
		},
	},
};
</script>
