<template lang="pug">
//- Modal Product Setting
Dialog(header="Header" v-model:visible="addProductSetting" @update:visible="updatemodal")
  template(#header)
    h3 Add Product Setting
  .row
    .col-lg-6
      .form-group
        label(for='producttype') Product Type
        input#producttype.form-control(type="text" placeholder="Product Type" v-model="producttype")
      .form-group
        label(for='productsize') Product Size
        input#productsize.form-control(type="text" placeholder="Product Size" v-model="productsize")
      .form-group
        label(for='inflatepressure') Inflate Pressure
        input#inflatepressure.form-control(type="text" placeholder="Inflate Pressure" v-model="inflatepressure")
      .form-group
        label(for='inflate1') Inflate 1
        input#inflate1.form-control(type="text" placeholder="Inflate 1" v-model="inflate1")
      .form-group
        label(for='inflate2') Inflate 2
        input#inflate2.form-control(type="text" placeholder="Inflate 2" v-model="inflate2")
      .form-group
        label(for='ejectassist') Reject Assist
        select.form-control#ejectassist(v-model="ejectassist")
          option No
          option Yes
      .form-group
        label(for='gloveillumination') Glove illumination
        select.form-control#gloveillumination(v-model="gloveillumination")
          option No
          option Yes With Glove
          option Yes W/O Glove
    .col-lg-6
      .form-group
        label(for='airejectgood') Air Eject Good
        input#airejectgood.form-control(type="text" placeholder="Air Eject Good" v-model="airejectgood")
      .form-group
        label(for='airejectretest') Air Eject Retest
        input#airejectretest.form-control(type="text" placeholder="Air Eject Retest" v-model="airejectretest")
      .form-group
        label(for='starttest') Start Test
        input#starttest.form-control(type="text" placeholder="Start Test" v-model="starttest")
      .form-group
        label(for='testfor') Test For
        input#testfor.form-control(type="text" placeholder="Test For" v-model="testfor")
      .form-group
        label(for='passcode') Pass Code
        select.form-control#passcode(v-model="passcode")
          option(selected) NONE
          option PPPP
          option RRRR
          option FFFF
          option FFRP
          option RFFP
          option PRFF
      .form-group
        label(for='glovetype') Glove Type
        select.form-control#glovetype(v-model="glovetype")
          option Latex
          option Nitrile
          option Other
      .form-group(v-if="glovetype == 'Other'")
        label(for='stateglovetype') State Glove Type
        input#stateglovetype.form-control(type="text" placeholder="State Glove Type" v-model="stateglovetype")
  template(#footer)
    button.btn.btn-append(@click="updatemodal") Discard
    button.btn.btn-primary(type='button' v-on:click='productSave') Add
</template>
<script>
const ejectAssistType = { No: 0, Yes: 1 };
const gloveIlluminationType = {
	No: 0,
	"Yes With Glove": 1,
	"Yes W/O Glove": 2,
};
export default {
	emits: ["product-setting-modal"],
	props: {
		addProductSetting: Boolean,
	},
	data() {
		return {
			userid: parseInt(this.$cookies.get("UserID")),
			globalcompany: "",
			producttitle: "",
			producttype: "",
			productsize: "",
			glovetype: "Latex",
			inflatepressure: "",
			inflate1: "",
			inflate2: "",
			ejectassist: "No",
			gloveillumination: "No",
			airejectgood: "",
			airejectretest: "",
			starttest: "",
			testfor: "",
			passcode: "NONE",
			glovetype: "Latex",
			stateglovetype: "",
		};
	},
	mounted() {
		// this.globalcompany = this.$cookies.get("CompanyID");
	},
	computed: {},
	methods: {
		updatemodal() {
			this.$emit("product-setting-modal", false);
			this.producttype = "";
			this.productsize = "";
			this.glovetype = "Latex";
			this.inflatepressure = "";
			this.inflate1 = "";
			this.inflate2 = "";
			this.rejectassist = "No";
			this.gloveillumination = "No";
			this.airejectgood = "";
			this.airejectretest = "";
			this.starttest = "";
			this.testfor = "";
			this.passcode = "NONE";
			this.glovetype = "Latex";
			this.stateglovetype = "";
		},
		productSave: function () {
			if (!this.producttype || !this.productsize || !this.glovetype) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the product details",
					life: 3000,
				});
			} else {
				if (this.validateSetting()) {
					const data = JSON.stringify({
						producttype: this.producttype,
						productsize: this.productsize,
						inflatepressure: parseFloat(this.inflatepressure),
						inflate1: parseFloat(this.inflate1),
						inflate2: parseFloat(this.inflate2),
						ejectassist: parseFloat(ejectAssistType[this.ejectassist]),
						gloveillumination: parseFloat(
							gloveIlluminationType[this.gloveillumination]
						),
						airejectgood: parseFloat(this.airejectgood),
						airejectretest: parseFloat(this.airejectretest),
						starttest: parseFloat(this.starttest),
						testfor: parseFloat(this.testfor),
						passcode: this.passcode,
						glovetype:
							this.glovetype == "Other" ? this.stateglovetype : this.glovetype,
						companyid: parseInt(this.$cookies.get("CompanyID")),
						createuserid: this.userid,
						modifyuserid: this.userid,
					});
					this.$store.commit("GET_ProductSetting", data);
					this.$store
						.dispatch("postAPIwithRandom", "newproduct")
						.then((response) => {
							if (response == 200) {
								//Do message pop up here
								//Close the modal
								this.$toast.add({
									severity: "success",
									summary: "Success",
									detail: "Product Setting had updated",
									life: 3000,
								});
								this.$store.dispatch(
									"getProductSetting",
									parseInt(this.$cookies.get("CompanyID"))
								);
								this.updatemodal();
							}
						})
						.catch((error) => {
							this.$toast.add({
								severity: "error",
								summary: "Failed",
								detail: "Product Setting not able to update",
								life: 3000,
							});
							console.log(error);
						});
				}
			}
		},
		validateSetting() {
			if (this.inflate1 < 0 || this.inflate1 > 5) {
				this.$toast.add({
					severity: "error",
					summary: "Failed",
					detail: "Inflate 1 invalid.",
					life: 5000,
				});
				return false;
			} else if (this.inflate2 < 0 || this.inflate2 > 10) {
				this.$toast.add({
					severity: "error",
					summary: "Failed",
					detail: "Inflate 2 invalid.",
					life: 5000,
				});
				return false;
			} else if (this.airejectgood < 0 || this.airejectgood > 10) {
				this.$toast.add({
					severity: "error",
					summary: "Failed",
					detail: "Air Eject Good invalid.",
					life: 5000,
				});
				return false;
			} else if (this.airejectretest < 0 || this.airejectretest > 10) {
				this.$toast.add({
					severity: "error",
					summary: "Failed",
					detail: "Air Eject Retest invalid.",
					life: 5000,
				});
				return false;
			} else if (this.starttest < 0 || this.starttest > 50) {
				this.$toast.add({
					severity: "error",
					summary: "Failed",
					detail: "Start Test invalid.",
					life: 5000,
				});
				return false;
			} else if (this.testfor < 0 || this.testfor > 255) {
				this.$toast.add({
					severity: "error",
					summary: "Failed",
					detail: "Test For invalid.",
					life: 5000,
				});
				return false;
			}
			return true;
		},
	},
};
</script>
