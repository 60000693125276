<template lang="pug">
//- ChargesRateModal(
//-     :chargesRate="chargesRate"
//-     :dispatchRate="ReportSelectedCompany"
//-     @charges-rate-modal="ChargesRateModal"
//- )
//- Modal Add Report
Dialog(header="Header" v-model:visible="addReport"  @update:visible="clearModal")
    template(#header)
        h3 Edit Report
    .row
        .col-lg-12
            .form-group
                label(for='company') Company Name
                select.form-control#company(v-model="selectedCompany" @change="selectMachineByCompany($event)")
                    option(value='0', disabled='') Please Select Company
                    option(v-for='item in companylist',  v-bind:value='item.Id') {{item.CompanyName}}
            .form-group
                label(for="reportid") Report ID
                input#reportid.form-control(type="text" placeholder="Report ID"  v-model='reportId')
            .form-group
                label(for="reportRemark") Notes
                input#reportnotes.form-control(type="text" placeholder="Notes"  v-model='reportRemark')
        
        .col-lg-6
            .row
                .col-lg-12
                    h6.my-2 Date
                    .form-group
                        label(for="serial") Report Date Range
                        Calendar(v-model="reportDateRange" dateFormat="dd/mm/yy" yearRange="1900:2100" selectionMode="range")

                .col-lg-12   
                    .d-flex.align-items-center.justify-content-between.my-2.mt-5
                        h6 Calculations
                        .btn.btn-append(@click="!isRateButtonDisabled? showRate():''" :disabled="isRateButtonDisabled") Rate 
                    .form-group
                        label(for='currency') Currency
                        select.form-control(v-model='selectedCurrency' v-if='rateCurrency=="MYR"' :disabled='true')
                            //- option USD
                            //- option MYR
                            option(v-for='item in currencyCode' v-bind:value='item.code') {{item.code}} | {{item.name}}
                        select.form-control(v-model='selectedCurrency' v-else='selectedCurrency=="MYR"')
                            option(v-for='item in currencyCode' v-bind:value='item.code') {{item.code}} | {{item.name}}
                    .form-group
                        label(for="discounts") Discounts (%)
                        input#discounts.form-control(type="number" placeholder="Discounts (%)" v-model='reportDiscount')

        .col-lg-6
            h6.mt-2 Machine
            .mt-2.form-group
                label(for="machine") Selected machine
                multiselect.multiselect-custom(v-model='selectedMachine' :options='machineList' optionLabel='SerialNumber'  placeholder='Select Machines' :filter='true')
                    template(#value='slotProps')
                        .country-item.country-item-value(v-for='option of slotProps.value' :key='option.Id')
                            div - {{option.SerialNumber}}
                        template(v-if='!slotProps.value || slotProps.value.length === 0')
                            | Select Machine
                    template(#option='slotProps')
                        .country-item
                            div {{slotProps.option.SerialNumber}}
    template(#footer)
        button.btn.btn-append(@click="clearModal") Discard
        button.btn.btn-primary(type='button' @click="calculateReport()") Save  

Dialog(header="Header" v-model:visible="isShowRate"  ref='formDialog' @update:visible="clearRateModal()")
    template(#header)
        h3 Current Rate
        .mb-4
    .sub-title.mb-1 Input Currency
    .form-group
        label(for='currency') Currency
        select.form-control#currency(:disabled='true' v-model='rateCurrency')
            option USD
            option MYR
    .sub-title.mb-1 Charges Rate (per 1000 pcs) 
    .form-group
        label(for="passrate") Pass
        input#passrate.form-control(type="number" placeholder="Pass Price" v-model="passRate" :disabled='true')
    .form-group
        label(for='retestrate') Retest
        input#retestrate.form-control(type="number" placeholder="Retest Price" v-model="retestRate" :disabled='true')
    .form-group
        label(for='failrate') Fail
        input#failrate.form-control(type="number" placeholder="Fail Price" v-model="failRate" :disabled='true')
    .form-group
        label(for='rejectrate') Reject
        input#rejectrate.form-control(type="number" placeholder="Reject Price" v-model="rejectRate" :disabled='true')
    .form-group
        label(for='nogloverate') No glove
        input#nogloverate.form-control(type="number" placeholder="No glove Price" v-model="noGloveRate" :disabled='true')
    
    template(#footer)
        .d-flex.justify-content-between.align-items-center
            .small.mr-auto  Last Updated: {{modifiedDate}}
            div
                button.btn.btn-append(@click="clearRateModal") Close 
</template>
<style lang="scss" scoped>
.p-multiselect.multiselect-custom {
	width: 100%;
}
</style>
<script>
import ChargesRateModal from "@/components/ChargesRateModal.vue";
import moment from "moment";
import axios from "axios";
const currencyjson = require("../assets/currency.json");

export default {
	emits: ["report-modal"],
	props: {
		addReport: Boolean,
	},
	components: {
		ChargesRateModal,
	},
	data() {
		return {
			isShowRate: false,
			isRateButtonDisabled: true,
			reportDateRange: null,
			reportId: "",
			reportRemark: "",
			reportDiscount: "",
			// chargesRate : false,
			// globalcompany: this.$cookies.get("CompanyID"),
			// userid: parseInt(this.$cookies.get("UserID")),
			// SelectedDate: null,
			selectedCompany: 0,
			selectedMachine: null,
			selectedCurrency: null,
			currencyCode: [],
			passRate: "",
			failRate: "",
			retestRate: "",
			rejectRate: "",
			noGloveRate: "",
			modifiedDate: "",
			rateCurrency: "",
			// rate:null,
		};
	},
	mounted() {
		this.currencyCode = Object.keys(currencyjson).map((key) => {
			return {
				code: key,
				name: currencyjson[key],
			};
		});
	},
	computed: {
		companylist() {
			return this.$store.state.companylist;
		},
		machineList() {
			return this.$store.state.machinelist;
		},
		rate() {
			return this.$store.state.rate;
		},
	},
	methods: {
		clearModal() {
			this.selectedCompany = 0;
			this.selectedMachine = null;
			this.selectedCurrency = "";
			this.reportId = "";
			this.reportRemark = "";
			this.rateCurrency = "";
			(this.passRate = ""),
				(this.failRate = ""),
				(this.retestRate = ""),
				(this.rejectRate = ""),
				(this.noGloveRate = ""),
				(this.modifiedDate = ""),
				(this.rateCurrency = ""),
				(this.isRateButtonDisabled = true);
			this.$emit("report-modal", false);

			// Clear all text field function here
		},
		ChargesRateModal(event) {
			this.chargesRate = event;
		},
		openModal() {
			this.chargesRate = true;
		},
		calculateReport() {
			// get report data
			var machines = [];

			this.selectedMachine.forEach((item) => {
				machines.push(item.SerialNumber);
			});

			var machineLs = machines.join(",");

			const data = {
				ReportId: this.reportId,
				CompanyId: this.selectedCompany,
				Remarks: this.reportRemark,
				FromDate: moment(this.reportDateRange[0], "DD/MM/YYYY").format(
					"YYYY-MM-DD"
				),
				ToDate: moment(this.reportDateRange[1], "DD/MM/YYYY").format(
					"YYYY-MM-DD"
				),
				Machines: machineLs,
				Discount: Number(this.reportDiscount),
				Currency: this.selectedCurrency,
			};

			this.$store.commit("Update_Report", data);

			axios
				
			
			
				/* For URL control - 29 Aug 2023 */
				.post(this.$server_url + "Report/InsertReportOption", data)

				//Original
				//.post("https://www.qmaxv4.com:8443/api/Report/InsertReportOption", data)



				.then((response) => {
					if (response.status == 200) {
						this.$toast.add({
							severity: "success",
							summary: "Success Message",
							detail: this.reportId + "has been updated.",
							life: 3000,
						});
						// this.$store.dispatch("getReportList");
						var that = this;
						setTimeout(function () {
							that.$store.dispatch("getReportList");
						}, 7000);

						let route = this.$router.resolve({
							path: "/report-preview/" + response.data,
						});
						window.open(route.href);
						that.clearModal();
					}

					// this.$store.dispatch("getReportList");
				})
				.catch((error) => {
					this.$toast.add({
						severity: "error",
						summary: "Failed Message",
						detail: "Error Inserting Report: " + this.reportId,
						life: 3000,
					});
				});
		},
		showRate() {
			this.isShowRate = true;
			this.passRate = this.rate.PassRate;
			this.failRate = this.rate.FailRate;
			this.retestRate = this.rate.RetestRate;
			this.rejectRate = this.rate.RejectRate;
			this.noGloveRate = this.rate.NoGloveRate;
			this.modifiedDate = this.rate.ModifyDate;
			this.rateCurrency = this.rate.Currency;
		},
		selectMachineByCompany(event) {
			this.machineList = this.$store.dispatch(
				"getMachineList",
				event.target.value
			);
			this.rate = this.$store
				.dispatch("getRateSettingForReport", this.selectedCompany)
				.then(() => {
					this.selectedCurrency = this.rate.Currency;
					this.rateCurrency = this.rate.Currency;
				});
			this.isRateButtonDisabled = false;
			this.selectedMachine = null;
		},
		clearRateModal() {
			this.isShowRate = false;
		},
	},
};
</script>
