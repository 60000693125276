import { createStore } from "vuex";
import axios from "axios";
import moment from "moment";
import vue from "vue";

export default createStore({
	state: {
		//URL Parameter
		productionAPIRunning: false,
		statusAPIRunning: false,
		APIController: "",
		//Machine Information
		currentcompany: [],
		currentcompanyname: [],
		currentmachine: [],
		machineinfo: [],
		machinestatus: [],
		damachinestatus: [],
		production: [],
		batch: [],
		batchlog: [],
		batchlist: [],
		batchlistwithbatch: [],
		eventlist: [],
		faultlist: [],
		machinelist: [],
		newmachine: [],
		callibrationlist: [],
		licenselist: [],
		userlist: [],
		//Company Information
		companylist: [],
		//Employee Information
		employeelist: [],
		employeeinfo: [],
		//Setting Information
		productsetting: [],
		newproduct: [],
		newrate: [],
		rate: [],
		//Card Information
		cards: [],
		newcards: [],
		reports: [],
		operationlevel: [
			{
				id: 0,
				name: "Level 0 - UNIPAC",
			},
			{
				id: 1,
				name: "Level 1 - Manager",
			},
			{
				id: 2,
				name: "Level 2 - Supervisor",
			},
			{
				id: 3,
				name: "Level 3 - Operator",
			},
		],
		//User Information
		managerlist: [],
		//Generation Information
		gen: [
			{
				id: 4,
				name: "V4",
			},
			{
				id: 5,
				name: "V5",
			},
		],
		versionlist: [],
		//Login Information
		login: [],
		//Access OL
		empOP: [
			{
				id: 2,
				name: "Management",
			},
			{
				id: 3,
				name: "Engineering",
			},
			{
				id: 4,
				name: "Production",
			},
		],
		serviceproviderlog: [],
		clientlog: [],
		//Data Analysis
		Pass: [],
		Fail: [],
		Reject: [],
		Retest: [],
		NoGlove: [],
		Total: [],
		Meter: [],
		Yield: [],
		LocalCMU: [],
		operator: [],
		reportdetails: [],
		selectedReport: [],



		/* For URL control - 29 Aug 2023 */
		// Local server (access from internet)
		//server_url: "http://202.188.213.195:8866/api/",
		
		// Local server (access from local network)
		//server_url: "http://192.168.1.75:8866/api/",
		
		// Clould server
		server_url: "https://www.qmaxv4.com:8443/api/",
		


	},
	mutations: {
		//Machine Information
		SET_Info(state, posts) {
			state.machineinfo = posts;
		},
		SET_Status(state, posts) {
			state.machinestatus = posts;
		},
		SET_DAStatus(state, posts) {
			state.damachinestatus = posts;
		},
		SET_Production(state, posts) {
			state.production = posts;
		},
		SET_Batch(state, posts) {
			state.batch = posts;
		},
		SET_BatchLog(state, posts) {
			state.batchlog = posts;
		},
		SET_BatchList(state, posts) {
			state.batchlist = posts;
		},
		SET_EventList(state, posts) {
			state.eventlist = posts;
		},
		SET_BatchListWithBatch(state, posts) {
			state.batchlistwithbatch = posts;
		},
		SET_ModuleFaultList(state, posts) {
			state.faultlist = posts;
		},
		SET_MachineList(state, posts) {
			state.machinelist = posts;
		},
		SET_AllMachineList(state, posts) {
			state.allmachinelist = posts;
		},
		GET_MachineInfo(state, gets) {
			state.newmachine = gets;
		},
		SET_ReportDetails(state, posts) {
			state.reportdetails = posts;
		},
		SET_CurrentCompany(state, posts) {
			state.currentcompany = posts;
		},
		SET_CurrentCompanyName(state, posts) {
			state.currentcompanyname = posts;
		},
		SET_CurrentMachine(state, posts) {
			state.currentmachine = posts;
		},
		SET_Callibration(state, posts) {
			state.callibrationlist = posts;
		},
		SET_License(state, posts) {
			state.licenselist = posts;
		},
		//Company Information
		SET_CompanyInfo(state, posts) {
			state.companylist = posts;
		},
		//Employee Information
		SET_EmployeeList(state, posts) {
			state.employeelist = posts;
		},
		GET_EmployeeInfo(state, posts) {
			state.employeeinfo = posts;
		},
		GET_Employee(state, posts) {
			state.employee = posts;
		},
		SET_Employee(state, posts) {
			state.selectedEmployee = posts;
		},
		GET_Operator(state, posts) {
			state.operator = posts;
		},
		//Setting Information
		SET_ProductSetting(state, posts) {
			state.productsetting = posts;
		},
		SET_RateSetting(state, posts) {
			state.rate = posts;
		},
		GET_ProductSetting(state, posts) {
			state.newproduct = posts;
		},
		GET_RateSetting(state, posts) {
			state.newrate = posts;
		},
		//RFID Informatiom
		SET_CardList(state, posts) {
			state.cards = posts;
		},
		Get_Card(state, posts) {
			state.newcards = posts;
		},
		SET_ReportList(state, posts) {
			state.reports = posts;
		},
		//User Information
		SET_ManagerList(state, posts) {
			state.managerlist = posts;
		},
		//Version Information
		SET_VersionList(state, posts) {
			state.versionlist = posts;
		},
		SET_UserMailInfo(state, posts) {
			state.userlist = posts;
		},
		//Login Information
		GET_Login(state, posts) {
			state.login = posts;
		},
		//Add Client
		GET_Client(state, posts) {
			state.client = posts;
		},
		//Audit Log
		Get_ServiceProviderLog(state, posts) {
			state.serviceproviderlog = posts;
		},
		Get_ClientLog(state, posts) {
			state.clientlog = posts;
		},
		//Data Analysis
		Get_Pass(state, posts) {
			state.Pass = posts;
		},
		Get_Fail(state, posts) {
			state.Fail = posts;
		},
		Get_Reject(state, posts) {
			state.Reject = posts;
		},
		Get_Retest(state, posts) {
			state.Retest = posts;
		},
		Get_NoGlove(state, posts) {
			state.NoGlove = posts;
		},
		Get_Total(state, posts) {
			state.Total = posts;
		},
		Get_Meter(state, posts) {
			state.Meter = posts;
		},
		Get_Yield(state, posts) {
			state.Yield = posts;
		},
		GetLocalCMU(state, posts) {
			state.LocalCMU = posts;
		},
		Update_Report(state, posts) {
			state.selectedReport = posts;
		},
	},
	getters: {
		Get_StateValue: (state) => (stateGet) => {
			if (stateGet == "newmachine") {
				return state.newmachine;
			} else if (stateGet == "updatemachine") {
				return state.newmachine;
			} else if (stateGet == "newoperator") {
				return state.employeeinfo;
			} else if (stateGet == "newproduct") {
				return state.newproduct;
			} else if (stateGet == "updateproduct") {
				return state.newproduct;
			} else if (stateGet == "newrate") {
				return state.newrate;
			} else if (stateGet == "newcard") {
				return state.newcards;
			} else if (stateGet == "login") {
				return state.login;
			} else if (stateGet == "newuser") {
				return state.login;
			} else if (stateGet == "updateEmployee") {
				return state.selectedEmployee;
			} else if (stateGet == "updateRFID") {
				return state.newcards;
			} else if (stateGet == "updateReportOption") {
				return state.selectedReport;
			} else if (stateGet == "saveReportOption") {
				return state.selectedReport;
			} else if (stateGet == "newclient") {
				return state.client;
			} else if (stateGet == "updateClient") {
				return state.client;
			} else if (stateGet == "updateOperator") {
				return state.operator;
			} else if (stateGet == "localcmu") {
				return state.newmachine;
			}
		},
		GET_API: (state) => (stateGet) => {
			
			
			
			/* For URL control - 29 Aug 2023 */
			// Local server (access from internet)
			//state.APIController = "http://202.188.213.195:8866/api/";

			// Local server (access from local network)
			//state.APIController = "http://192.168.1.75:8866/api/";
						
			// Clould server
			state.APIController = "https://www.qmaxv4.com:8443/api/";
			


			if (stateGet == "newmachine") {
				return (state.APIController += "MachineInfo/SaveMachine");
			} else if (stateGet == "updatemachine") {
				return (state.APIController += "MachineInfo/UpdateMachine");
			} else if (stateGet == "newoperator") {
				return (state.APIController += "Employee/SaveOperator");
			} else if (stateGet == "updateOperator") {
				return (state.APIController += "Employee/UpdateOperator");
			} else if (stateGet == "newproduct") {
				return (state.APIController += "Setting/SaveProductSetting");
			} else if (stateGet == "updateproduct") {
				return (state.APIController += "Setting/UpdateProductSetting");
			} else if (stateGet == "newrate") {
				return (state.APIController += "Setting/SaveRateSetting");
			} else if (stateGet == "newcard") {
				return (state.APIController += "RFID/SaveRFID");
			} else if (stateGet == "updateRFID") {
				return (state.APIController += "RFID/UpdateRFID");
			} else if (stateGet == "updateReportOption") {
				return (state.APIController += "Report/UpdateReportOption");
			} else if (stateGet == "saveReportOption") {
				return (state.APIController += "Report/InsertReportOption");
			} else if (stateGet == "login") {
				return (state.APIController += "user/Login");
			} else if (stateGet == "newuser") {
				return (state.APIController += "user/CreateUser");
			} else if (stateGet == "updateEmployee") {
				return (state.APIController += "user/UpdateEmployee");
			} else if (stateGet == "newclient") {
				return (state.APIController += "Company/CreateUser");
			} else if (stateGet == "updateClient") {
				return (state.APIController += "Company/UpdateCompany");
			} else if (stateGet == "localcmu") {
				return (state.APIController += "Company/ActivateLocalCMU");
			}
		},
	},
	actions: {
		ResetMachineDetails({ commit }) {
			var emptySet = {};
			commit("SET_Info", emptySet);
			commit("SET_Status", emptySet);
			commit("SET_Production", emptySet);
			// commit("SET_BatchList", emptySet);
			// commit("Get_Yield", emptySet);
			// commit("SET_EventList", emptySet);
			// commit("Get_Meter", emptySet);
			// commit("SET_License", emptySet);
			// commit("SET_Callibration", emptySet);
			// commit("SET_ModuleFaultList", emptySet);
		},
		postAPIwithRandom({ commit, getters }, stateGet) {
			return new Promise((resolve, reject) => {
				axios
					.post(getters.GET_API(stateGet), getters.Get_StateValue(stateGet), {
						headers: {
							"Content-Type": "application/json",
						},
					})
					.then((response) => {
						resolve(response.status);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		//Machine Actions
		getUserEmailInfo({ commit }) {
			axios
				
			
			
				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "User/GetUserList")
			
				// Original
				//.get("https://www.qmaxv4.com:8443/api/User/GetUserList")



				.then((response) => {
					commit("SET_UserMailInfo", response.data);
				});
		},
		getMachineInfo({ commit }, machineid) {
			axios
				
			

				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "MachineInfo/" + machineid)
				
				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/" + machineid)



				.then((response) => {
					commit("SET_Info", response.data);
				});
		},
		getMachineList({ commit }, companyid) {
			if (companyid != null && companyid != "") {
				axios
					
				
				
					/* For URL control - 29 Aug 2023 */
					.get(
						this.state.server_url + "MachineInfo/GetMachineWCompany/" +
							companyid
					)
				
					// Original
					/* .get(
						"https://www.qmaxv4.com:8443/api/MachineInfo/GetMachineWCompany/" +
							companyid
					) */



					.then((response) => {
						commit("SET_MachineList", response.data);
					});
			}
		},
		async getMachineListForReport({ commit }, companyid) {
			if (companyid != null && companyid != "") {
				return axios
					
				
				
					/* For URL control - 29 Aug 2023 */
					.get(
						this.state.server_url + "MachineInfo/GetMachineWCompany/" +
							companyid
					)
				
					// Original
					/* .get(
						"https://www.qmaxv4.com:8443/api/MachineInfo/GetMachineWCompany/" +
							companyid
					) */



					.then((response) => {
						commit("SET_MachineList", response.data);
					});
			}
		},
		async getReportById({ commit }, reportId) {
			return axios
				
			
			
				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "Report/GetReport/" + reportId)
			
				// Original
				//.get("https://www.qmaxv4.com:8443/api/Report/GetReport/" + reportId)



				.then((response) => {
					commit("SET_ReportDetails", response.data);
				});
		},
		getAllMachineList({ commit }, companyid) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetAllMachineWCompany/"
				)

				// Original	
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetAllMachineWCompany/"
				) */



				.then((response) => {
					commit("SET_AllMachineList", response.data);
				});
		},
		getMachineStatus({ state, commit }, machineid) {
			if (!state.statusAPIRunning) {
				state.statusAPIRunning = true;
				axios
					
				
				
					/* For URL control - 29 Aug 2023 */
					.get(
						this.state.server_url + "MachineInfo/GetStatus/" + machineid
					)
				
					// Original
					/* .get(
						"https://www.qmaxv4.com:8443/api/MachineInfo/GetStatus/" + machineid
					) */
					

					
					.then((response) => {
						if (response.data != "") {
							commit("SET_Status", response.data);
						}

						state.statusAPIRunning = false;
					});
			}
		},
		async getCallibration({ commit }, machineid) {
			await axios
				
			
			
				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetCallibrationList/" +
						machineid
				)
			
				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetCallibrationList/" +
						machineid
				) */



				.then((response) => {
					commit("SET_Callibration", response.data);
				});
		},
		async getLicense({ commit }, machineid) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetLicenseList/" +
						machineid
				)

				// Original	
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetLicenseList/" +
						machineid
				) */



				.then((response) => {
					commit("SET_License", response.data);
				});
		},
		getDAMachineStatus({ commit }, comID) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetOccupancy/" + comID
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetOccupancy/" + comID
				) */
				
				
				
				.then((response) => {
					commit("SET_DAStatus", response.data);
				});
		},
		getProduction({ state, commit }, machineid) {
			if (!state.productionAPIRunning) {
				state.productionAPIRunning = true;
				axios



					/* For URL control - 29 Aug 2023 */
					.get(
						this.state.server_url + "MachineInfo/GetProduction/" +
							machineid
					)

					// Original
					/* .get(
						"https://www.qmaxv4.com:8443/api/MachineInfo/GetProduction/" +
							machineid
					) */



					.then((response) => {
						if (response.data != "") {
							commit("SET_Production", response.data);
						}

						state.productionAPIRunning = false;
					});
			}
		},
		getBatch({ commit }, BatchNo) {
			axios
				
			
			
				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "MachineInfo/GetBatch/" + BatchNo)
			
				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/GetBatch/" + BatchNo)
				
				
				
				.then((response) => {
					commit("SET_Batch", response.data);
				});
		},
		getBatchLog({ commit }, BatchNo) {
			if (BatchNo == null || BatchNo == "") {
				BatchNo = "0";
			}
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "MachineInfo/GetBatch/" + BatchNo)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/GetBatch/" + BatchNo)
				
				
				
				.then((response) => {
					commit("SET_BatchLog", response.data);
				});
		},
		async getBatchList({ commit }, id) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "MachineInfo/GetBatchList/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/GetBatchList/" + id)
				
				
				
				.then((response) => {
					commit("SET_BatchList", response.data);
				});
		},
		async getBatchListbySearch({ commit }, payload) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetBatchList/" +
						payload.id +
						"?key=" +
						(payload.batchSearch_no == null ? "" : payload.batchSearch_no) +
						"&startDate=" +
						(payload.batchSearch_startDate == null
							? ""
							: payload.batchSearch_startDate) +
						"&endDate=" +
						(payload.batchSearch_endDate == null
							? ""
							: payload.batchSearch_endDate)
				)

				// Original						
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetBatchList/" +
						payload.id +
						"?key=" +
						(payload.batchSearch_no == null ? "" : payload.batchSearch_no) +
						"&startDate=" +
						(payload.batchSearch_startDate == null
							? ""
							: payload.batchSearch_startDate) +
						"&endDate=" +
						(payload.batchSearch_endDate == null
							? ""
							: payload.batchSearch_endDate)
				) */
				
				
				
				.then((response) => {
					commit("SET_BatchList", response.data);
				});
		},
		getBatchListWithBatch({ commit }, id) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_urll + "MachineInfo/GetBatchSingle/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/GetBatchSingle/" + id)
				
				
				
				.then((response) => {
					commit("SET_BatchListWithBatch", response.data);
				});
		},
		async getEventList({ commit }, id) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "MachineInfo/GetEventLog/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/GetEventLog/" + id)
				
				
				
				.then((response) => {
					commit("SET_EventList", response.data);
				});
		},
		async getEventLogListbySearch({ commit }, payload) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetEventLog/" +
						payload.id +
						"?startDate=" +
						(payload.eventSearch_startDate == null
							? ""
							: payload.eventSearch_startDate) +
						"&endDate=" +
						(payload.eventSearch_endDate == null
							? ""
							: payload.eventSearch_endDate)
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetEventLog/" +
						payload.id +
						"?startDate=" +
						(payload.eventSearch_startDate == null
							? ""
							: payload.eventSearch_startDate) +
						"&endDate=" +
						(payload.eventSearch_endDate == null
							? ""
							: payload.eventSearch_endDate)
				) */
				
				
				
				.then((response) => {
					commit("SET_EventList", response.data);
				});
		},
		async getModuleFaultList({ commit }, id) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "MachineInfo/GetModuleLog/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/MachineInfo/GetModuleLog/" + id)
				
				
				
				.then((response) => {
					commit("SET_ModuleFaultList", response.data);
				});
		},
		async getModuleFaultListbySearch({ commit }, payload) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "MachineInfo/GetModuleLog/" +
						payload.id +
						"?startDate=" +
						(payload.faultSearch_startDate == null
							? ""
							: payload.faultSearch_startDate) +
						"&endDate=" +
						(payload.faultSearch_endDate == null
							? ""
							: payload.faultSearch_endDate)
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/MachineInfo/GetModuleLog/" +
						payload.id +
						"?startDate=" +
						(payload.faultSearch_startDate == null
							? ""
							: payload.faultSearch_startDate) +
						"&endDate=" +
						(payload.faultSearch_endDate == null
							? ""
							: payload.faultSearch_endDate)
				) */
				
				
				
				.then((response) => {
					commit("SET_ModuleFaultList", response.data);
				});
		},
		//Company Action
		getCompanyInfo({ commit }) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "Company")
				
				// Original
				//.get("https://www.qmaxv4.com:8443/api/Company")
				
				
				
				.then((response) => {
					commit("SET_CompanyInfo", response.data);
				});
		},
		//Employee Action
		getEmployeeList({ commit }, id) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "Employee/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/Employee/" + id)
				
				
				
				.then((response) => {
					commit("SET_EmployeeList", response.data);
				});
		},
		//Setting Action
		getProductSetting({ commit }, id) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "setting/GetProductSetting/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/setting/GetProductSetting/" + id)
				
				
				
				.then((response) => {
					commit("SET_ProductSetting", response.data);
				});
		},
		getRateSetting({ commit }, id) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "setting/GetRateSetting/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/setting/GetRateSetting/" + id)
				
				
				
				.then((response) => {
					commit("SET_RateSetting", response.data);
				});
		},
		async getRateSettingForReport({ commit }, id) {
			return axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "setting/GetRateSetting/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/setting/GetRateSetting/" + id)
				
				
				
				.then((response) => {
					commit("SET_RateSetting", response.data);
				});
		},
		//RFID Action
		getCardList({ commit }, payload) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "RFID/GetRFIDList/" +
						payload.companyId +
						"/" +
						payload.UserId
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/RFID/GetRFIDList/" +
						payload.companyId +
						"/" +
						payload.UserId
				) */
				
				
				
				.then((response) => {
					commit("SET_CardList", response.data);
				});
		},
		async getReportList({ commit }) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "Report/GetReportList")

				// Original
				//.get("https://www.qmaxv4.com:8443/api/Report/GetReportList")
				
				
				
				.then((response) => {
					var data = this.dispatch("formatReportList", response.data).then(
						(result) => {
							commit("SET_ReportList", result);
						}
					);
				});
		},
		formatReportList({ commit }, val) {
			val.forEach((element) => {
				element.CreateDate = new Date(
					moment(String(element.CreateDate), "DD/MM/YYYY hh:mm:ss").format(
						"MM/DD/YYYY HH:mm:ss"
					)
				);
				element.ModifyDate = new Date(
					moment(String(element.ModifyDate), "DD/MM/YYYY hh:mm:ss").format(
						"MM/DD/YYYY HH:mm:ss"
					)
				);
			});
			var test = [...val];

			return test;
		},
		//copy from axios till here
		//User Action
		getManagerList({ commit }, id) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "User/GetManagerList/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/User/GetManagerList/" + id)
				
				
				
				.then((response) => {
					commit("SET_ManagerList", response.data);
				});
		},
		//Version Action
		getVersionList({ commit }, id) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "Version/GetVersionList/" + id)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/Version/GetVersionList/" + id)
				
				
				
				.then((response) => {
					var data = this.dispatch("formatVersionList", response.data).then(
						(result) => {
							commit("SET_VersionList", result);
						}
					);
				});
		},
		formatVersionList({ commit }, val) {
			val.forEach((element) => {
				element.CreateDate = new Date(
					moment(String(element.CreateDate), "DD/MM/YYYY hh:mm:ss").format(
						"MM/DD/YYYY HH:mm:ss"
					)
				);
				element.ModifyDate = new Date(
					moment(String(element.ModifyDate), "DD/MM/YYYY hh:mm:ss").format(
						"MM/DD/YYYY HH:mm:ss"
					)
				);
			});
			var test = [...val];

			return test;
		},

		//replace here
		//replace set version l with in commit, stay with result
		//},
		//Version Action
		getUserInfo({ commit }, userinfo) {
			axios
				.get(
					this.$api_url +
						"/User/GetUserInformation/" +
						userinfo.username +
						"/" +
						userinfo.password
				)
				.then((response) => {
					commit("GET_Login", response.data);
				});
		},
		//AuditLog
		getServiceProviderLog({ commit }, payload) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(
					this.state.server_url + "User/GetServiceProviderLog/" +
						payload.userId
				)

				// Original
				/* .get(
					"https://www.qmaxv4.com:8443/api/User/GetServiceProviderLog/" +
						payload.userId
				) */
				
				
				
				.then((response) => {
					if (payload.companyId == -1) {
						var data = this.dispatch(
							"formatServiceProviderLogList",
							response.data
						).then((result) => {
							commit("Get_ServiceProviderLog", result);
						});
					} else {
						var data = this.dispatch(
							"formatServiceProviderLogList",
							response.data
						).then((result) => {
							var tmp = result.filter(function (e) {
								return e.CompanyId == payload.companyId;
							});

							commit("Get_ServiceProviderLog", tmp);
						});
					}
				});
		},
		formatServiceProviderLogList({ commit }, val) {
			val.forEach((element) => {
				element.CreateDate = new Date(
					moment(String(element.CreateDate), "DD/MM/YYYY hh:mm:ss").format(
						"MM/DD/YYYY HH:mm:ss"
					)
				);
				if (element.CompanyName == null) {
					element.CompanyName = "System";
				}
			});
			var test = [...val];

			return test;
		},
		getClientLog({ commit }, userid) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "User/GetCompanyLog/" + userid)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/User/GetCompanyLog/" + userid)
				
				
				
				.then((response) => {
					var data = this.dispatch(
						"formatClientAuditLogList",
						response.data
					).then((result) => {
						commit("Get_ClientLog", result);
					});
				});
		},
		formatClientAuditLogList({ commit }, val) {
			val.forEach((element) => {
				element.CreateDate = new Date(
					moment(String(element.CreateDate), "DD/MM/YYYY hh:mm:ss").format(
						"MM/DD/YYYY HH:mm:ss"
					)
				);
			});
			var test = [...val];

			return test;
		},
		//Data Analsysis
		async GetMeter({ commit }, comid) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "DA/GetMeter/" + comid)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/DA/GetMeter/" + comid)
				
				
				
				.then((response) => {
					commit("Get_Meter", response.data);
				});
		},
		GetYield({ commit }, comid) {
			axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "DA/GetYield/" + comid)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/DA/GetYield/" + comid)
				
				
				
				.then((response) => {
					commit("Get_Yield", response.data);
				});
		},
		async GetYieldCompany({ commit }, comid) {
			await axios



				/* For URL control - 29 Aug 2023 */
				.get(this.state.server_url + "DA/GetYieldCompany/" + comid)

				// Original
				//.get("https://www.qmaxv4.com:8443/api/DA/GetYieldCompany/" + comid)
				
				
				
				.then((response) => {
					commit("Get_Yield", response.data);
				});
		},
	},
	modules: {},
});
