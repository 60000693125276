<template lang="pug">
//- company id need to be company name and show all machine list
.machinelist
  .d-flex.align-items-center.justify-content-between
    h4.my-3 Machine List
  DataTable(:value="machinelist" :paginator="true" :rows="10" responsiveLayout="scroll"
    v-model:filters="filtersmachine"  :loading="loadingmachine" 
    :globalFilterFields="['SerialNumber','CompanyName','Createdate','Recordstatus']"
    
    )
    template(#header)
      .form-group.append.search
        input.form-control(type='text' v-model="filtersmachine['global'].value" placeholder="Keyword Search")
        i.cil-search
        //- .btn.btn-primary(@click="clearFilter1()") Clear
    template(#empty) No Machine Found.
    template(#loading) Loading Machine Data. Please wait.
    Column(headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible" header="No")
      template(#body="rowIndex")
        | {{ rowIndex.index + 1 }}
    Column(field="SerialNumber" header="Machines" :sortable="true")
    Column(field="CompanyName" header="Company Name" :sortable="true")
    Column(field="Createdate" header="Created Date" :sortable="true" filterMatchMode="startsWith" style="width:20%")
      template(#body="slotProps") {{formatDate(slotProps.data.Createdate)}}
    Column(headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible")
      template(#body="slotProps")
        .d-flex.align-items-center.justify-content-center
          //- template(v-if="slotProps.data.Recordstatus !== 'Off'")
          //-   router-link(:to="{name: 'Machine Details', params: {id: slotProps.data.Id}}").btn.btn-small.btn-info.mx-2 
          //-     i.cil-view-module 
          .btn.btn-small.btn-info.mx-2(v-on:click="EditBox(slotProps.data)")
            i.cil-pencil
          .btn.btn-small.btn-danger.mx-2(v-on:click="machineRemove(slotProps.data.Id)")
            i.cil-trash

Dialog(header="Header" v-model:visible="editMachine"  @update:visible="updateModal" :style="{ width: '800px' }" :breakpoints="{'960px': '75vw', '640px': '100vw'} ")
  template(#header)
    h3 Edit Machine
  .row
    .col-lg-6
      .form-group
        label(for="serial") Serial Number
        input#serial.form-control(type="text" placeholder="Serial Number" v-model="SerialNumber")
      .form-group
        label(for='location') Location
        input#location.form-control(type="text" placeholder="Location" v-model="location")
      .form-group
        label(for='yearsmanufacturing') Years of Manufacturing
        input#yearsmanufacturing.form-control(type="text" placeholder="Years of Manufacturing" v-model="Years")
      //- If add in company machine list page, it will auto input company name
      .form-group(v-if="this.$route.name == 'Machine Dashboard'")
        label(for='company') Company Name
        select.form-control#company(v-model="MachineCompanySelect")
            option(value='0', disabled='') Please Select Company
            option(v-for='item in companylist', v-bind:value='item.Id') {{item.CompanyName}}
      .form-group
        label(for='software') Software Version
        select.form-control#software(v-model="SoftwareVersionSelect")
            option(value='0', disabled='') Please Select Software Version
            option(v-for='item in softwareVersionList', v-bind:value='item') {{item}}
      //- End
    .col-lg-6
      .form-group
        label(for='sshipaddress') SSH IP Address
        input#sshipaddress.form-control(type="text" placeholder="SSH IP Address" v-model="sshipaddress")
      .form-group
        label(for='sshport') SSH Port
        input#sshport.form-control(type="text" placeholder="SSH Port" v-model="sshport")
      .form-group
        label(for='sshusername') SSH User Name
        input#sshusername.form-control(type="text" placeholder="SSH User Name" v-model="sshusername")
      .form-group
        label(for='databaseserver') Database Server
        input#databaseserver.form-control(type="text" placeholder="Database Server" v-model="databaseserver")
      .form-group
        label(for='databasename') Database Name
        input#databasename.form-control(type="text" placeholder="Database Name" v-model="databasename")
      .form-group
        label(for='databaseusername') Database Username
        input#databaseusername.form-control(type="text" placeholder="Database Username" v-model="databaseusername")
      .form-group
        label(for='databasepassword') Database Password
        input#databasepassword.form-control(type="password" placeholder="Database Password" v-model="databasepassword")
      .form-group
        label(for='anydeskID') Anydesk ID 
        input#anydeskID.form-control(type="text" placeholder="Anydesk ID" v-model="anydeskID")
  template(#footer)
      button.btn.btn-append(@click="updatemodal") Discard
      button.btn.btn-primary(type='button' v-on:click='machineUpdate') Update
</template>
<script>
import axios from "axios";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
	data() {
		return {
			editMachine: false,
			filtersmachine: null,
			loadingmachine: true,
			SerialNumber: "",
			Years: "",
			location: "",
			sshipaddress: "",
			sshport: "",
			sshusername: "",
			databaseserver: "",
			databasename: "",
			databaseusername: "",
			databasepassword: "",
			anydeskID: "",
			userid: parseInt(this.$cookies.get("UserID")),
			MachineCompanySelect: 0,
			selectedId: "",
			SoftwareVersionSelect: 0,
			softwareVersionList: ["V4", "V5"],
		};
	},
	computed: {
		machinelist() {
			return this.$store.state.allmachinelist;
		},
		companylist() {
			return this.$store.state.companylist;
		},
	},
	created() {
		this.initFiltersmachine();
	},
	mounted() {
		this.$store.dispatch("getAllMachineList");
		this.loadingmachine = false;
	},
	methods: {
		// clearFilter1() {
		//   this.initFiltersmachine();
		// },
		formatDate: function (val) {
			return moment(val,"YYYY/MM/DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");	// Corrected date sorting - 19122024
		},
		initFiltersmachine() {
			this.filtersmachine = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
		machineRemove: function (machineid) {
			this.$confirm.require({
				message: "Are you sure you want to proceed?",
				header: "Confirmation",
				icon: "pi pi-exclamation-triangle",
				accept: () => {
					//callback to execute when user confirms the action
					axios



						/* For URL control - 29 Aug 2023 */
						.post(
							this.$server_url + "MachineInfo/RemoveMachine/" +
								machineid
						)

						// Original
						/* .post(
							"https://www.qmaxv4.com:8443/api/MachineInfo/RemoveMachine/" +
								machineid
						) */
						
						
						
						.then((response) => {
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: "Machine Success Removed",
								life: 5000,
							});
							this.$store.dispatch("getAllMachineList");
						});
				},
				reject: () => {
					//callback to execute when user rejects the action
				},
			});
		},
		EditBox(val) {
			this.selectedId = val.Id;
			this.editMachine = true;
			this.MachineCompanySelect = val.CompanyId;
			this.SerialNumber = val.SerialNumber;
			this.Years = val.YearOfManufac;
			this.location = val.Location;
			this.SoftwareVersionSelect = val.TargetSoftwareVersion;
			this.sshipaddress = val.Sshipaddress;
			this.sshport = val.Sshport;
			this.sshusername = val.Sshusername;
			this.databaseserver = val.Databaseserver;
			this.databasename = val.Databasename;
			this.databaseusername = val.Databaseusername;
			this.databasepassword = val.Databasepassword;
			this.anydeskID = val.AnydeskID;
		},
		updateModal() {
			this.selectedId = "";
			this.editMachine = false;
			this.MachineCompanySelect = 0;
			this.SerialNumber = "";
			this.Years = "";
			this.location = "";
			this.sshipaddress = "";
			this.sshport = "";
			this.sshusername = "";
			this.databaseserver = "";
			this.databasename = "";
			this.databaseusername = "";
			this.databasepassword = "";
			this.anydeskID = "";
			this.SoftwareVersionSelect = 0;
		},
		machineUpdate() {
			if (
				!this.SerialNumber ||
				!this.Years ||
				!this.location ||
				this.SoftwareVersionSelect == 0
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the machine details",
					life: 5000,
				});
			} else if (
				!this.sshport ||
				!this.sshipaddress ||
				!this.sshusername ||
				!this.sshusername
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the machine ssh details",
					life: 5000,
				});
			} else if (
				!this.databasename ||
				!this.databaseusername ||
				!this.databasepassword ||
				!this.databaseserver
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the machine database details",
					life: 5000,
				});
			} else {
				const data = JSON.stringify({
					id: this.selectedId,
					serialnumber: this.SerialNumber,
					yearofmanufac: this.Years,
					location: this.location,
					companyid: this.MachineCompanySelect,
					sshipaddress: this.sshipaddress,
					sshport: this.sshport,
					sshusername: this.sshusername,
					databaseserver: this.databaseserver,
					databasename: this.databasename,
					databaseusername: this.databaseusername,
					databasepassword: this.databasepassword,
					anydeskid: this.anydeskID,
					targetSoftwareVersion: this.SoftwareVersionSelect,
				});

				this.$store.commit("GET_MachineInfo", data);
				this.$store
					.dispatch("postAPIwithRandom", "updatemachine")
					.then((response) => {
						if (response == 200) {
							//Do message pop up here
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: this.SerialNumber + " machine updated",
								life: 5000,
							});
							this.$store.dispatch("getAllMachineList");
							this.updateModal();
						}
					})
					.catch((error) => {
						if (error.response.status == 400) {
							this.$toast.add({
								severity: "error",
								summary: "Error Message",
								detail:
									"Duplicated Serial Number: " + this.SerialNumber + " found.",
								life: 5000,
							});
						} else {
							this.$toast.add({
								severity: "error",
								summary: "Error Message",
								detail: "Error updating: " + this.SerialNumber + ".",
								life: 5000,
							});
						}
					});
			}
		},
	},
};
</script>
