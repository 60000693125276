<template lang="pug">
//- Modal Add Clients
Dialog(header="Header" v-model:visible="addClients" @update:visible="updatemodal")
    template(#header)
        h3 Add Clients
    .form-group
        label(for='companyname') Company Name
        input#companyname.form-control(type="text" placeholder="Company Name" v-model="registercompanyname")
    .form-group
        label(for='companyaddress') Company Address
        input#companyaddress.form-control(type="text" placeholder="Company Address" v-model="registercompanyaddress")
    .form-group
        label(for='email') Email
        input#email.form-control(type="text" placeholder="Email" v-model="registercompanyemail")
    .form-group
        label(for='maxaccountregisterlimit') Max Account Register
        input#maxaccountregisterlimit.form-control(type="text" placeholder="Number Account Allow Create" v-model="maxaccountselect")
    template(#footer)
        button.btn.btn-append(@click="updatemodal") Discard
        button.btn.btn-primary(type='button' @click='NewClient' ) Create
</template>
<script>
export default {
  emits: ["clients-modal"],
  props: {
    addClients: Boolean,
  },
  data() {
    return {
      registercompanyname: "",
      registercompanyaddress: "",
      registercompanyemail: "",
      maxaccountregisterlist: [10, 15, 20],
      maxaccountselect: "0",
      userid: parseInt(this.$cookies.get("UserID")),
      companywholelist: [this.$store.state.companylist],
      userwholelist: [this.$store.state.userlist],
    };
  },
  mounted() {
    this.$store.dispatch("getCompanyInfo");
    this.$store.dispatch("getUserEmailInfo");
    this.operationLevel;
  },
  computed: {
    operationLevel() {
      return this.$store.state.operationlevel;
    },
  },
  methods: {
    updatemodal() {
      this.$emit("clients-modal", false);
      this.registercompanyname = "";
      this.registercompanyaddress = "";
      this.registercompanyemail = "";
      this.maxaccountselect = "0";
    },
    NewClient(event) {
      var duplicatedemail;

      for (var i = 0; i < this.companywholelist[0].length; i++) {
        if (
          this.registercompanyemail == this.$store.state.companylist[i].Email
        ) {
          duplicatedemail = true;
        }
      }

      for (var i = 0; i < this.userwholelist[0].length; i++) {
        if (this.registercompanyemail == this.$store.state.userlist[i].Email) {
          duplicatedemail = true;
        }
      }

      if (
        !this.registercompanyname ||
        !this.registercompanyaddress ||
        !this.registercompanyemail ||
        this.maxaccountselect === "0"
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Form not complete",
          detail: "Please complete the form",
          life: 3000,
        });
      } else if (duplicatedemail === true) {
        this.$toast.add({
          severity: "error",
          summary: "Duplicated Information",
          detail: "Email is already in the system",
          life: 3000,
        });
      } else {
        const data = JSON.stringify({
          companyname: this.registercompanyname,
          companyaddress: this.registercompanyaddress,
          email: this.registercompanyemail.toLowerCase(),
          maxaccount: parseInt(this.maxaccountselect),
          createuserid: this.userid,
          modifyuserid: this.userid,
          // User Role always is 1 , - Client Admin , only client admin can assign role 2,3,4
        });
        this.$store.commit("GET_Client", data);
        this.$store
          .dispatch("postAPIwithRandom", "newclient")
          .then((response) => {
            if (response == 200) {
              //Do message pop up here
              this.$toast.add({
                severity: "success",
                summary: "Success Message",
                detail:
                  this.registercompanyname +
                  " has been registered with the email " +
                  this.registercompanyemail,
                life: 3000,
              });
              this.$store.dispatch("getCompanyInfo");
              this.updatemodal();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Failed Message",
                detail: this.registercompanyname + " not able to register ",
                life: 3000,
              });
            }
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: "Failed Message",
              detail: this.registercompanyname + " not able to register at all ",
              life: 3000,
            });
            //Do Fail saved message
          });
      }
    },
  },
};
</script>
