<template lang="pug">
//- Modal Add Employee For CLient Admin View
Dialog(header="Header" v-model:visible="addEmployee" @update:visible="updatemodal")
    template(#header)
        h3 Create Access 
    .form-group
        label(for="employee-name") Employee Name
        input#employee-name.form-control(type="text" v-model="employeename" placeholder="Employee Name")
    .form-group
        label(for="employee-id") Employee ID
        input#employee-id.form-control(type="text" v-model="employeeid" placeholder="Employee ID")
    .form-group
        label(for="employee-name") Employee Email
        input#employee-name.form-control(type="email" v-model="employeeeemail" placeholder="Employee Email")
    .form-group
        label Role
        select.form-control#operationLevel(v-model="selectedEmpOL")
            option(value='0', disabled='') Please Select Operation Level
            option(v-for='item in empOL', v-bind:value='{id: item.id, name: item.name}') {{item.name}}
    template(#footer)
        button.btn.btn-append(@click="updatemodal") Discard
        button.btn.btn-primary(type='button'  v-on:click='createAccess') Create
</template>
<script>
export default {
	emits: ["employee-modal"],
	props: {
		addEmployee: Boolean,
	},
	data() {
		return {
			userid: parseInt(this.$cookies.get("UserID")),
			globalcompany: this.$cookies.get("CompanyID"),
			selectedEmpOL: 0,
			employeeid: "",
			employeename: "",
			employeeeemail: "",
			employeewholelist: [this.$store.state.managerlist],
			userwholelist: [this.$store.state.userlist],
		};
	},
	mounted() {
		this.$store.dispatch("getUserEmailInfo");
	},
	computed: {
		empOL() {
			return this.$store.state.empOP;
		},
	},
	methods: {
		updatemodal() {
			this.selectedEmpOL = 0;
			this.employeeid = "";
			this.employeename = "";
			this.employeeeemail = "";
			this.$emit("employee-modal", false);
		},
		// Havent Complete
		createAccess: function () {
			var duplicatedemail;
			for (var i = 0; i < this.employeewholelist[0].length; i++) {
				if (this.employeeeemail == this.employeewholelist[0][i].Email) {
					duplicatedemail = true;
				}
			}

			for (var i = 0; i < this.userwholelist[0].length; i++) {
				if (this.employeeeemail == this.$store.state.userlist[i].Email) {
					duplicatedemail = true;
				}
			}

			if (
				!this.employeeid ||
				!this.employeename ||
				!this.employeeeemail ||
				!this.selectedEmpOL.id
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the employee details",
					life: 3000,
				});
			} else if (duplicatedemail === true) {
				this.$toast.add({
					severity: "error",
					summary: "Duplicated Information",
					detail: "Email is already in the system",
					life: 3000,
				});
			} else {
				const data = JSON.stringify({
					employeeid: this.employeeid,
					employeename: this.employeename,
					email: this.employeeeemail.toLowerCase(),
					companyid: parseInt(this.globalcompany),
					recordstatus: "Active",
					createuserid: this.userid,
					modifyuserid: this.userid,
					userrole: parseInt(this.selectedEmpOL.id),
				});
				this.$store.commit("GET_Login", data);
				this.$store
					.dispatch("postAPIwithRandom", "newuser")
					.then((response) => {
						if (response == 200) {
							//Do message pop up here
							//Close the modal
							this.$toast.add({
								severity: "success",
								summary: "Success Message",
								detail: this.employeename + " had created",
								life: 3000,
							});
							this.$store.dispatch("getManagerList", this.globalcompany);
							this.updatemodal();
						}
					})
					.catch((error) => {
						if (error.response.status == 412) {
							this.$toast.add({
								severity: "error",
								summary: "Fail Message",
								detail:
									"User " +
									this.employeename +
									" is not created. Maximum user reached.",
								life: 6000,
							});
						} else if (error.response.status == 400) {
							this.$toast.add({
								severity: "error",
								summary: "Fail Message",
								detail:
									"User " +
									this.employeename +
									" is not created. Duplicate user found.",
								life: 6000,
							});
						} else {
							this.$toast.add({
								severity: "error",
								summary: "Fail Message",
								detail: "User " + this.employeename + " is not created.",
								life: 6000,
							});
						}
						// this.updatemodal();
					});
			}
		},
	},
};
</script>
