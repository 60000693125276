<template lang="pug">
//- Modal Charges Rate
Dialog(header="Header" v-model:visible="chargesRate"  ref='formDialog' @update:visible="updatemodal")
    template(#header)
        h3 Charges Rate
        .mb-4
    .sub-title.mb-1 Input Currency
    .form-group
        label(for='currency') Currency
        select.form-control#currency(v-model="currencyrate")
            option USD
            option MYR
    .sub-title.mb-1 Charges Rate (per 1000 pcs) 
    .form-group
        label(for="passrate") Pass
        input#passrate.form-control(type="number" placeholder="Pass Price" v-model="passrate" v-on:input="validate($event)")
    .form-group
        label(for='retestrate') Retest
        input#retestrate.form-control(type="number" placeholder="Retest Price" v-model="retestrate" v-on:input="validate($event)")
    .form-group
        label(for='failrate') Fail
        input#failrate.form-control(type="number" placeholder="Fail Price" v-model="failrate" v-on:input="validate($event)")
    .form-group
        label(for='rejectrate') Reject
        input#rejectrate.form-control(type="number" placeholder="Reject Price" v-model="rejectrate" v-on:input="validate($event)")
    .form-group
        label(for='nogloverate') No glove
        input#nogloverate.form-control(type="number" placeholder="No glove Price" v-model="nogloverate" v-on:input="validate($event)")
    
    template(#footer)
        .d-flex.justify-content-between.align-items-center
            .small.mr-auto  Last Updated: {{ modifydate }}
            div
                button.btn.btn-append(@click="updatemodal") Close
                button.btn.btn-primary(type='button' v-on:click='rateSave') Save
</template>
<script>
export default {
	props: {
		companyId: Number,
		chargesRate: Boolean,
		dispatchRate: Object,
	},
	data() {
		return {
			userid: parseInt(this.$cookies.get("UserID")),
			currencyrate: "",
			passrate: "",
			retestrate: "",
			failrate: "",
			nogloverate: "",
			rejectrate: "",
			modifydate: "",
		};
	},
	watch: {
		chargesRate: function (val) {
			if (this.$store.state.rate != null) {
				this.dataupdated = this.$store.state.rate.ModifyDate;
				this.passrate = this.$store.state.rate.PassRate;
				this.retestrate = this.$store.state.rate.RetestRate;
				this.failrate = this.$store.state.rate.FailRate;
				this.nogloverate = this.$store.state.rate.NoGloveRate;
				this.rejectrate = this.$store.state.rate.RejectRate;
				this.currencyrate = this.$store.state.rate.Currency;
				this.modifydate = this.$store.state.rate.ModifyDate;
			} else {
				this.passrate = "";
				this.retestrate = "";
				this.failrate = "";
				this.nogloverate = "";
				this.rejectrate = "";
				this.currencyrate = "MYR";
				this.modifydate = "";
			}
		},
	},
	mounted() {
		// this.$store.dispatch("getRateSetting",this.companyId);
	},
	computed: {
		rate() {
			if (this.$store.state.rate != null) {
				this.dataupdated = this.$store.state.rate.ModifyDate;
				this.passrate = this.$store.state.rate.PassRate;
				this.retestrate = this.$store.state.rate.RetestRate;
				this.failrate = this.$store.state.rate.FailRate;
				this.nogloverate = this.$store.state.rate.NoGloveRate;
				this.rejectrate = this.$store.state.rate.RejectRate;
				this.currencyrate = this.$store.state.rate.Currency;
				this.modifydate = this.$store.state.rate.ModifyDate;
			} else {
				this.passrate = "";
				this.retestrate = "";
				this.failrate = "";
				this.nogloverate = "";
				this.rejectrate = "";
				this.currencyrate = "MYR";
				this.modifydate = "";
			}
		},
	},
	// watch:{
	//     dispatchRate(val){
	//         this.$store.dispatch("getRateSetting", val.id);
	//     }
	// },
	methods: {
		validate: function(event) {
			var rate = event.target.value.toString();
			rate = rate.substr(0, 10);
			event.target.value = parseFloat(rate.indexOf(".") == -1 ? rate : (rate.substr(0, rate.indexOf(".") + 3)));
			
			if (event.target.id == 'passrate') {
				this.passrate = event.target.value;
			}
			else if (event.target.id == 'retestrate'){
				this.retestrate = event.target.value;
			}
			else if (event.target.id == 'failrate'){
				this.failrate = event.target.value;
			}
			else if (event.target.id == 'rejectrate') {
				this.rejectrate = event.target.value;
			}
			else if (event.target.id == 'nogloverate') {
				this.nogloverate = event.target.value;
			}
		},
	
		updatemodal() {
			this.$emit("charges-rate-modal", false);
		},
		rateSave: function (event) {
			if (
				!this.passrate ||
				!this.retestrate ||
				!this.failrate ||
				!this.rejectrate ||
				!this.nogloverate
			) {
				this.$toast.add({
					severity: "error",
					summary: "Form not complete",
					detail: "Please fill in the rate details",
					life: 3000,
				});
			} else {
				const data = JSON.stringify({
					passrate: parseFloat(this.passrate),
					retestrate: parseFloat(this.retestrate),
					failrate: parseFloat(this.failrate),
					companyid: parseInt(this.$cookies.get("CompanyID")),
					rejectrate: parseFloat(this.rejectrate),
					nogloverate: parseFloat(this.nogloverate),
					createuserid: this.userid,
					modifyuserid: this.userid,
					currency: this.currencyrate,
				});
				//Do a confirmation alert box
				this.$confirm.require({
					message: "Are you sure you want to proceed?",
					header: "Confirmation",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						//callback to execute when user confirms the action
						this.$store.commit("GET_RateSetting", data);
						this.$store
							.dispatch("postAPIwithRandom", "newrate")
							.then((response) => {
								if (response == 200) {
									//Do message pop up here
									this.$toast.add({
										severity: "success",
										summary: "Success Message",
										detail: "Rate had updated",
										life: 3000,
									});
									this.$store.dispatch("getRateSetting", this.companyId);
									this.updatemodal();
								}
							})
							.catch((error) => {
								//Do Fail saved message
								this.$toast.add({
									severity: "error",
									summary: "Rejected",
									detail: "Rate updated fail",
									life: 3000,
								});
							});
					},
					reject: () => {
						this.$toast.add({
							severity: "error",
							summary: "Rejected",
							detail: "Rate updated fail",
							life: 3000,
						});
					},
				});
			}
		},
	},
};
</script>
