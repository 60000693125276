<template lang="pug">
div(:class='{ container : main }')
  Header(v-if="loginPage")
  router-view
</template>
<style lang="scss">
@import "src/assets/scss/style";
</style>

<script>
//@ is an alias to /src
import Header from "@/components/Header.vue";
export default {
	name: "Home",
	el: "#app",
	components: {
		Header,
	},
	data() {
		return {
			main: true,
		};
	},
	methods: {},
	created() {
		this.loginPage;
	},
	computed: {
		loginPage() {
			if (
				this.$route.name === "Login" ||
				this.$route.name === "Report Preview" ||
				this.$route.name === "Update Password"
			) {
				this.main = !this.main;
				return false;
			} else {
				this.main = true;
				return true;
			}
		},
	},
};
</script>
